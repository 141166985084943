import { cloneElement } from "react";
import { Link } from "react-router-dom";
import { OpenIcon } from "allIcons";
import { usePathname } from "helpers";
import { ModulePrefixes } from "enums";
import { ModulesRoutes } from "enums/modules";

export default function LeftMenuCard({ prefix, module, link, icon, title, mathcingTab }) {
  const [pathName, moduleName, subModuleName] = usePathname();

  let active = false;
  if (prefix.split("/").length === 1) {
    active = pathName === prefix && moduleName === module;
  } else {
    active = `${pathName}/${moduleName}` === prefix && subModuleName === module;
  }

  let comaparedModule = ModulesRoutes.INTERFACE;

  if (!moduleName && pathName === ModulePrefixes.SITE_SETTINGS && module === comaparedModule)
    active = true;
  if (!moduleName && pathName === ModulePrefixes.USERS && module === mathcingTab)
    active = true;
  if (!moduleName && pathName === ModulePrefixes.TEMPLATE_LIBRARY && module === ModulesRoutes.TASKS)
    active = true;
  
  if (!moduleName && pathName === ModulePrefixes.SETTINGS && module === ModulesRoutes.BRANDING_AND_COLORS)
    active = true;

  return (
    <>
      <Link
        to={`/${link}`}
        className={`menu-card`}
        style={active ? { backgroundColor: "#0066FF" } : {}}
      >
        <div className="flex flex-row gap-11 items-center">
          <div className={`menu-card-icon flex items-center justify-center `}>
            {cloneElement(icon, {
              fill: active ? "white" : "#0066FF",
            })}
          </div>
          <h2
            className={`text-13 font-semibold ${
              active ? "text-white" : "text-gray"
            } capitalize`}
          >
            {title}
          </h2>
        </div>
        <div>
          <OpenIcon fill={active ? "white" : "#4C5866"} />
        </div>
      </Link>
    </>
  );
}
