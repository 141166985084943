import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  IconWrapper,
  InputField,
  PrimaryHeading,
  RightMainContainer,
  MainButton,
  SecondaryHeading,
  AddComponent,
  ListCard,
  ListIconWrapper,
  PopOver,
  AddFromTemplateLibrary,
  Loader,
  DeleteButton,
  Divider,
  UserAvatar,
} from "commonUI";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { isEmpty, isEqual, parse, useUtilities } from "helpers";
import {
  useAddCompany,
  useCompanies,
  useDeleteCompany,
  useUpdateCompany,
} from "api/usersModule/companies";
import {
  useDefaultMembers,
  useMembers,
  useSiteRelateUsers,
} from "api/usersModule/members";
import { updateform } from "redux/reducers/usersModule/companies";
import { t } from "i18next";

export default function AddEditCompany() {
  const {
    notification,
    navigate,
    dispatch,
    companies: { form },
  } = useUtilities();

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const { id } = useParams();
  const [inputValues, setInputValues] = useState(
    isEmpty(form) || isEqual(id, "add") || !isEqual(form.id, id)
      ? {
          company_name: "",
          company_website: "",
          company_phone: "",
          company_email: "",
          company_address1: "",
          company_address2: "",
          company_country: "",
          company_city: "",
          company_zip_code: "",
          company_state: "",
          company_billing_address1: "",
          company_billing_address2: "",
          company_billing_country: "",
          company_billing_city: "",
          company_billing_zip_code: "",
          company_billing_state: "",
          users: [],
        }
      : form
  );
  function handleInputChange(key, value) {
    setInputValues({
      ...inputValues,
      [key]: value,
    });
  }

  const { data: companies, isLoading, error } = useCompanies();
  const { mutate: addCompany, isLoading: addingCompany } =
    useAddCompany("/users/companies/");
  const { mutate: deleteCompany, isLoading: deletingComapny } =
    useDeleteCompany("/users/companies");
  const { mutate: updateCompany, isLoading: updatingCompany } =
    useUpdateCompany();
  const { data: members, isLoading: loadingMembers } = useMembers();
  const { data: defaultMembers, isLoading: loadingDefaultMembers } =
    useDefaultMembers();
  const { data: siteUsers, isLoading: loadingSiteUsers } = useSiteRelateUsers();

  const usersList = useMemo(() => {
    if (
      loadingMembers ||
      loadingDefaultMembers ||
      loadingSiteUsers ||
      !members ||
      !defaultMembers ||
      !siteUsers
    )
      return {};
    return Object.fromEntries(
      [...members, ...defaultMembers, ...siteUsers].map((value) => [
        value.id,
        value,
      ])
    );
  }, [
    members,
    defaultMembers,
    siteUsers,
    loadingMembers,
    loadingDefaultMembers,
    loadingSiteUsers,
  ]);

  useEffect(() => {
    if (
      isLoading ||
      !companies ||
      isEqual(id, "add") ||
      !isEmpty(form) ||
      loadingMembers ||
      loadingDefaultMembers ||
      loadingSiteUsers
    )
      return;
    const company = companies.find((_) => isEqual(_.id, id));
    if (!company) return;
    setInputValues({
      ...company,
      users: parse(company.users).map((_) => ({
        ..._,
        website_id: parse(_.website_id),
        ...(usersList?.[_.id] ?? {}),
      })),
    });
  }, [
    isLoading,
    companies,
    id,
    form,
    usersList,
    loadingMembers,
    loadingDefaultMembers,
    loadingSiteUsers,
  ]);

  function handleSaveChanges() {
    if (inputValues.company_name === "") {
      notification({
        type: "error",
        message: t("users.companies.input.error.companyName"),
      });
      return;
    }
    if (
      inputValues.company_website &&
      !/^(http|https):\/\/[^ "]+$/.test(inputValues.company_website)
    ) {
      notification({
        type: "error",
        message: t("users.companies.input.error.validWebsite"),
      });
      return;
    }
    if (
      inputValues.company_email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.company_email)
    ) {
      notification({
        type: "error",
        message: t("users.companies.input.error.validEmail"),
      });
      return;
    }
    if (isEqual(id, "add")) {
      addCompany({
        ...inputValues,
        users: inputValues.users.map((_) => ({ id: _.id })),
        removeUsers: [],
      });
    } else {
      const company = companies.find((_) => isEqual(_.id, id));
      updateCompany({
        ...inputValues,
        company_id: id,
        users: inputValues.users.map((_) => ({ id: _.id })),
        removeUsers: parse(company.users)
          .filter(
            (_) => !inputValues.users.find((ele) => isEqual(ele.id, _.id))
          )
          .map((_) => ({ id: _.id })),
      });
    }
  }
  return (
    <>
      <RightMainContainer>
        <Loader
          show={addingCompany || updatingCompany || deletingComapny}
          loader="block"
        />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/users/companies")} />
            <PrimaryHeading>
              {isEqual(id, "add") ? t("users.addCompany.title") : inputValues.company_name}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={!isEqual(id, "add")}
              onDelete={() => deleteCompany(id)}
              deleteMessage={t("users.companies.delete.confirmation.description")}
              style={{ right: "-10px" }}
            />
            <MainButton onClick={handleSaveChanges}>
              <SaveIcon />{t("button.saveChanges")}
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <div>
            <PrimaryHeading className="text-17 mb-2">
              {t("users.companies.companyDetails.title")}
            </PrimaryHeading>
            <InputField
              label={t("users.companies.companyDetails.name.label")}
              placeholder={t("users.companies.companyDetails.name.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_name}
              setValue={(value) => handleInputChange("company_name", value)}
            />
            <InputField
              label={t("users.companies.companyDetails.website.label")}
              placeholder={t("users.companies.companyDetails.website.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_website}
              setValue={(value) => handleInputChange("company_website", value)}
            />
            <InputField
              type="number"
              label={t("users.companies.companyDetails.phone.label")}
              placeholder={t("users.companies.companyDetails.phone.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_phone}
              setValue={(value) => handleInputChange("company_phone", value)}
            />
            <InputField
              label={t("users.companies.companyDetails.email.label")}
              placeholder={t("users.companies.companyDetails.email.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_email}
              setValue={(value) => handleInputChange("company_email", value)}
            />
          </div>
          <Divider height={2} className="mt-5 mb-3" />
          <div>
            <PrimaryHeading className="text-17 mb-2">
              Company Address
            </PrimaryHeading>
            <InputField
              label={t("users.companies.companyAddress.addressLine1.label")}
              placeholder={t("users.companies.companyAddress.addressLine1.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_address1}
              setValue={(value) => handleInputChange("company_address1", value)}
            />
            <InputField
              label={t("users.companies.companyAddress.addressLine2.label")}
              placeholder={t("users.companies.companyAddress.addressLine2.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_address2}
              setValue={(value) => handleInputChange("company_address2", value)}
            />
            <InputField
              label={t("users.companies.companyAddress.country.label")}
              placeholder={t("users.companies.companyAddress.country.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_country}
              setValue={(value) => handleInputChange("company_country", value)}
            />
            <InputField
              label={t("users.companies.companyAddress.stateOrProvince.label")}
              placeholder={t("users.companies.companyAddress.stateOrProvince.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_state}
              setValue={(value) => handleInputChange("company_state", value)}
            />
            <InputField
              label={t("users.companies.companyAddress.city.label")}
              placeholder={t("users.companies.companyAddress.city.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_city}
              setValue={(value) => handleInputChange("company_city", value)}
            />
            <InputField
              label={t("users.companies.companyAddress.zipOrPostalCode.label")}
              placeholder={t("users.companies.companyAddress.zipOrPostalCode.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_zip_code}
              setValue={(value) => handleInputChange("company_zip_code", value)}
            />
          </div>
          <Divider height={2} className="mt-5 mb-3" />
          <div>
            <PrimaryHeading className="text-17 mb-2">
              {t("users.companies.companyBillingAddress.title")}
            </PrimaryHeading>
            <InputField
              label={t("users.companies.companyBillingAddress.addressLine1.label")}
              placeholder={t("users.companies.companyBillingAddress.addressLine1.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_billing_address1}
              setValue={(value) =>
                handleInputChange("company_billing_address1", value)
              }
            />
            <InputField
              label={t("users.companies.companyBillingAddress.addressLine2.label")}
              placeholder={t("users.companies.companyBillingAddress.addressLine2.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_billing_address2}
              setValue={(value) =>
                handleInputChange("company_billing_address2", value)
              }
            />
            <InputField
              label={t("users.companies.companyBillingAddress.country.label")}
              placeholder={t("users.companies.companyBillingAddress.country.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_billing_country}
              setValue={(value) =>
                handleInputChange("company_billing_country", value)
              }
            />
            <InputField
              label={t("users.companies.companyBillingAddress.stateOrProvince.label")}
              placeholder={t("users.companies.companyBillingAddress.stateOrProvince.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_billing_state}
              setValue={(value) =>
                handleInputChange("company_billing_state", value)
              }
            />
            <InputField
              label={t("users.companies.companyBillingAddress.city.label")}
              placeholder={t("users.companies.companyBillingAddress.city.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_billing_city}
              setValue={(value) =>
                handleInputChange("company_billing_city", value)
              }
            />
            <InputField
              label={t("users.companies.companyBillingAddress.zipOrPostalCode.label")}
              placeholder={t("users.companies.companyBillingAddress.zipOrPostalCode.placeholder")}
              className="gap-0 mb-1"
              inputDivClass="mb-0"
              value={inputValues.company_billing_zip_code}
              setValue={(value) =>
                handleInputChange("company_billing_zip_code", value)
              }
            />
          </div>
          <div>
            <SecondaryHeading>{t("users.companies.usersInCompany.title")}</SecondaryHeading>
            {inputValues.users.map((_, index) => (
              <ListCard
                key={index}
                data={`${_.name} ${_.last_name}`}
                secondaryData={isEqual(_.is_owner, 1) ? t("users.companies.usersInCompany.users.owner") : _.user_type}
                icon={
                  <ListIconWrapper className="bg-white">
                    <UserAvatar user={_} />
                  </ListIconWrapper>
                }
                onCross={() => {
                  setInputValues({
                    ...inputValues,
                    users: inputValues.users.filter((__, i) => i !== index),
                  });
                }}
                crossIcon={!isEqual(_.is_owner, 1)}
                deleteIcon={true}
                edit
                strictLink
                link={`/users/${
                  _.user_type === "Client" ? "clients" : "team-members"
                }/${_.id}${
                  _.website_id &&
                  Array.isArray(_.website_id) &&
                  _.website_id.length > 0
                    ? _.website_id.length > 1
                      ? `?backurl=/users/companies/${id}`
                      : `?backurl=/users/companies/${id}&web=${_.website_id[0]}`
                    : `?backurl=/users/companies/${id}`
                }`}
                onEditClick={() => {
                  dispatch(updateform({ ...inputValues, id }));
                }}
                showModalonCross
                deleteMessage={t("users.companies.usersInCompany.users.remove.confirmation", {
                  name: _.name,
                  last_name: _.last_name
                })}
              />
            ))}
            <AddComponent
              title={t("users.companies.usersInCompany.users.addUser")}
              onClick={() => setOpenAddUserModal(true)}
            >
              <PopOver
                open={openAddUserModal}
                onClose={setOpenAddUserModal}
                style={{
                  top: "auto",
                  bottom: "calc(100% + 15px)",
                  right: "auto",
                }}
                toolTip={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <AddFromTemplateLibrary
                  heading={t("users.companies.usersInCompany.users.addUser")}
                  isLoading={
                    loadingMembers || loadingDefaultMembers || loadingSiteUsers
                  }
                  data={Object.values(usersList)
                    .filter(
                      (_) =>
                        inputValues.users.findIndex((ele) =>
                          isEqual(ele.id, _.id)
                        ) === -1 || !_.user_associated_company
                    )
                    .map((item) => ({
                      ...item,
                      title: `${item.name} ${item.last_name}`,
                      icon: (
                        <UserAvatar user={item} />
                      ),
                      iconWrapperClass: "bg-white",
                      onClick: () =>
                        setInputValues({
                          ...inputValues,
                          users: [...inputValues.users, item],
                        }),
                    }))}
                  emptyTemplate={{
                    label: t("users.companies.usersInCompany.users.emptyLabel.user"),
                    linkLabel: t("users.companies.usersInCompany.users.emptyLabel.user"),
                    link: "/users/team-members/add",
                  }}
                />
              </PopOver>
            </AddComponent>
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
