import { useEffect, useState } from "react";
import { compact, isEqual, useSearch } from "helpers";
import "./index.css";
import { ModulePrefixes, SiteSettingsMenu } from "enums";
import { useUserPermissions } from "hooks/user";
import { useLocation, useNavigate } from "react-router-dom";
import { featureMenu, getGeneralMenu, siteTempleteUsersMenu } from "staticData";
import { t } from "i18next";

export default function SecondaryMenu({ isLibraryTemplateAllowed, ...props }) {
  const [activeMenu, setActiveMenu] = useState(
    props.activeMenu ?? SiteSettingsMenu.GENERAL_SETTINGS
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { add_items_from_template_library } = useUserPermissions();
  const { web } = useSearch();
  const menuItems = [
    {
      title: t(
        web ? "siteSettingTab.general" : "settingTab.defaultGeneralSettings"
      ),
      key: SiteSettingsMenu.GENERAL_SETTINGS,
      onClick: () =>
        navigate(
          web
            ? `/site-settings/interface${web ? `?web=${web}` : ""}`
            : "/site-settings/interface"
        ),
    },
    isLibraryTemplateAllowed && {
      title: t(web ? "siteSettingTab.users" : "settingTab.defaultUsers"),
      key: SiteSettingsMenu.USER_PREFRENCES,
      onClick: () =>
        navigate(`/site-settings/users${web ? `?web=${web}` : ""}`),
    },
    !web &&
      isLibraryTemplateAllowed &&
      add_items_from_template_library && {
        title: t("settingTab.defaultTemplates"),
        key: SiteSettingsMenu.LIBRARY_TEMPLATES,
        onClick: () =>
          navigate(
            `/site-settings/library-item/tasks${web ? `?web=${web}` : ""}`
          ),
      },
    {
      title: t(
        web ? "siteSettingTab.feature" : "settingTab.defaultFeatureSettings"
      ),
      key: SiteSettingsMenu.FEATURES_SETTINGS,
      onClick: () =>
        navigate(`/site-settings/environments${web ? `?web=${web}` : ""}`),
    },
  ];

  useEffect(() => {
    const module = pathname.split("/")[2];
    const isGeneralSetting = getGeneralMenu(true).find((ele) =>
      isEqual(ele.module, module)
  );
    if (isGeneralSetting) {
      handleClick(SiteSettingsMenu.GENERAL_SETTINGS);
      return;
    }

    const isFeatureSetting = featureMenu(web).find((ele) =>
      isEqual(ele.module, module)
    );
    if (isFeatureSetting) {
      handleClick(SiteSettingsMenu.FEATURES_SETTINGS);
      return;
    }

    const isSiteTempleteUsersMenu = siteTempleteUsersMenu.find((ele) =>
      isEqual(ele.module, module)
    );
    if (isSiteTempleteUsersMenu) {
      handleClick(SiteSettingsMenu.USER_PREFRENCES);
      return;
    }

    if (pathname.startsWith(`/${ModulePrefixes.SITE_SETTINGS_LIBRARY_ITEM}`)) {
      handleClick(SiteSettingsMenu.LIBRARY_TEMPLATES);
      return;
    }
  }, []);

  function handleClick(value) {
    props.setActiveMenu(value);
    setActiveMenu(value);
  }

  return (
    <div className="main-menu-list">
      {compact(menuItems).map((item) => (
        <div
          className={`main-menu-list-tab ${
            activeMenu === item.key ? "active-menu" : ""
          }`}
          onClick={() => {
            handleClick(item.key);
            item.onClick();
          }}
        >
          <h2>{item.title}</h2>
        </div>
      ))}
    </div>
  );
}
