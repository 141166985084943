import { SecondaryHeading } from "commonUI";
import { CheckIcon } from "allIcons";
import { t } from "i18next";

export default function DropDownCard({
  className = "",
  title,
  list,
  listStyle,
  onSelect,
  selectedValues = [],
  isEmpty = false,
}) {
  return (
    <div className={`dropdown-list ${className}`}>
      <div className="p-5">
        {title ? <SecondaryHeading>{title}</SecondaryHeading> : ""}
        <ul className={`${title ? "mt-3" : ""} p-0 list-style-none`}>
          {list.length > 0 ? (
            list.map((item, index) => (
              <li
                key={index}
                className={`dropdown-item body-text ${
                  item.icon ? "dropdown-item-icon" : ""
                }`}
                onClick={() =>
                  !selectedValues.includes(item.id) && onSelect(item)
                }
              >
                {listStyle ? (
                  <div
                    className="dropdown-item-circle"
                    style={{ background: item.color }}
                  ></div>
                ) : (
                  ""
                )}
                {item.icon}
                {item.title || item.text ? item.title || item.text : item}
                {selectedValues?.includes(
                  item.id ?? (item.title || item.text)
                ) ? (
                  <div className="dropdown-item-check">
                    <CheckIcon />
                  </div>
                ) : (
                  ""
                )}
              </li>
            ))
          ) : (
            <div className="body-text">
              {isEmpty || list.length === 0
                ? t("common.dropDown.noItemsToAdd")
                : t("common.dropDown.noMoreItemsToAdd")}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}
