import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, GlossaryIcon } from "allIcons";
import {
  BodyContainer,
  FiltersList,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
} from "commonUI";
import {
  useGlosssary,
  useAddGlossary,
  useDeleteGlossary,
} from "api/templateLibrary/glossary";
import { Link } from "react-router-dom";
import { parse, search } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function Glossary() {
  const { data: glossary, isLoading, error } = useGlosssary();
  const { mutate: addGlossary, isLoading: isAdding } = useAddGlossary(false);
  const { mutate: deleteGlossary, isLoading: isDeleting } =
    useDeleteGlossary(false);

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    setSearchResult(
      glossary.filter((task) => search(task.glossary_title, defferedSearch))
    );
  }, [defferedSearch, glossary]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult, "glossary_title")
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        glossary
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {(isAdding || isDeleting) && <Loader loader="block" />}
        <HeaderContainer>
          <PrimaryHeading>Glossary</PrimaryHeading>
          {add_new_templates ? (
            <Link to="add">
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Glossary"
              link={add_new_templates ? "add" : ''}
              linkLabel="Glossary Item"
            />
          ) : (
            Children.toArray(
              searchResult?.map((ele, index) => {
                return (
                  <ListCard
                    data={ele.glossary_title}
                    duplicate={add_new_templates}
                    editIcon={edit_templates}
                    link={
                      edit_templates
                        ? `/template-library/glossary/${ele.id}`
                        : ""
                    }
                    strictLink={true}
                    deleteIcon={!delete_templates}
                    onDuplicate={() => {
                      addGlossary({
                        glossary_title: `Copy of ${ele.glossary_title}`,
                        glossary_body: ele.glossary_body,
                        glossary_integration: ele.glossary_integration,
                        tags: parse(ele.tags),
                      });
                    }}
                    onDelete={() => deleteGlossary(ele.id)}
                    icon={
                      <ListIconWrapper>
                        <GlossaryIcon fill="white" />
                      </ListIconWrapper>
                    }
                    deleteTitle="Delete This Template?"
                    deleteButtonText="Confirm Delete"
                    deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
