import {
  BodyContainer,
  HeaderContainer,
  PrimaryHeading,
  RightMainContainer,
  InputField,
  IconWrapper,
  MainButton,
  TextEditor,
  Loader,
  DeleteButton,
  SecondaryHeading,
  ListCard,
  ListIconWrapper,
} from "commonUI";
import { useEffect, useState } from "react";
import {
  BackArrow,
  CheckListIcon,
  ContentRequestIcon,
  SaveIcon,
  TaskIcon,
} from "allIcons";
import { getUserSavePermission, isEqual, parse, useUtilities } from "helpers";
import {
  useAddNewPhase,
  usePhases,
  useUpdatePhase,
  useDeletePhase,
} from "api/templateLibrary/phases";
import { useParams } from "react-router-dom";
import { useUserPermissions } from "hooks/user";

const ModuleIcons = {
  checklist: { icon: <CheckListIcon fill="white" /> , link: "/template-library/checklists/" },
  content: { icon: <ContentRequestIcon />, link: "/template-library/content-request-templates/" },
  task: { icon: <TaskIcon />, link: "/template-library/tasks/" },
};

export default function AddEditPhase() {
  const { id } = useParams();
  const { navigate, notification } = useUtilities();
  const [values, setValues] = useState({
    title: "",
    description: "",
    target_date_days: 10,
    status: { text: "Upcoming", color: "#FFD000" },
    assignments: [],
  });
  const { data: phases, isLoading: loadingPhases } = usePhases();
  const { mutate: addPhase, isLoading: isAdding } = useAddNewPhase(
    "/template-library/phases/"
  );
  const { mutate: updatePhase, isLoading: isUpdating } = useUpdatePhase();
  const { mutate: deletePhase, isLoading: isDeleting } = useDeletePhase(
    "/template-library/phases/"
  );
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    if (isEqual(id, "add") || loadingPhases) return;

    const phase = phases.filter((ele) => isEqual(ele.id, id))[0];
    if (!phase) {
      navigate("/template-library/phases");
      return;
    }
    setValues({
      title: phase?.title ?? "",
      description: phase?.description ?? "",
      target_date_days: phase?.target_date_days ?? 10,
      assignments: phase?.assignments ?? [],
    });
  }, [loadingPhases, phases]);

  const handleSaveChanges = () => {
    if (!values.title) {
      notification({
        type: "ERROR",
        message: "Please enter title",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addPhase(values);
      return;
    }
    const phase = phases.filter((ele) => isEqual(ele.id, id))[0];
    updatePhase({ ...phase, status: parse(phase.status), ...values });
  };

  return (
    <>
      <RightMainContainer>
        <Loader loader="block" show={isAdding || isUpdating || isDeleting} />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/template-library/phases")} />
            <PrimaryHeading>
              {isEqual(id, "add") ? "Add Phase" : values.title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deletePhase(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_templates,
              editPermission: edit_templates,
            }) ? (
              <MainButton onClick={handleSaveChanges}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={values.title}
            setValue={(val) => setValues({ ...values, title: val })}
          />
          <TextEditor
            option={false}
            rows={5}
            label="Description"
            placeholder="Write Description Here"
            value={values.description}
            setValue={(val) => setValues({ ...values, description: val })}
          />
          <InputField
            label="Phase Duration Days"
            type="number"
            value={values.target_date_days}
            setValue={(val) => setValues({ ...values, target_date_days: val })}
            className="mt-3 mb-3"
          />
          <div>
            <SecondaryHeading>Associated Assignments</SecondaryHeading>
            {values.assignments.map((assignment, index) => (
              <ListCard
                data={assignment.title}
                strictLink
                icon={
                  <ListIconWrapper>
                    {ModuleIcons[assignment.parent_type].icon}
                  </ListIconWrapper>
                }
                deleteIcon
                link={`${ModuleIcons[assignment.parent_type].link}${assignment.id}`}
              />
            ))}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
