export default function MoveableIcon({ className, fill = "#4C5866" }) {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.57143 2.90909C9.36041 2.90909 10 2.25787 10 1.45455C10 0.651222 9.36041 0 8.57143 0C7.78245 0 7.14286 0.651222 7.14286 1.45455C7.14286 2.25787 7.78245 2.90909 8.57143 2.90909Z"
        fill={fill}
      />
      <path
        d="M8.57143 9.45455C9.36041 9.45455 10 8.80332 10 8C10 7.19668 9.36041 6.54545 8.57143 6.54545C7.78245 6.54545 7.14286 7.19668 7.14286 8C7.14286 8.80332 7.78245 9.45455 8.57143 9.45455Z"
        fill={fill}
      />
      <path
        d="M8.57143 16C9.36041 16 10 15.3488 10 14.5455C10 13.7421 9.36041 13.0909 8.57143 13.0909C7.78245 13.0909 7.14286 13.7421 7.14286 14.5455C7.14286 15.3488 7.78245 16 8.57143 16Z"
        fill={fill}
      />
      <path
        d="M1.42857 2.90909C2.21755 2.90909 2.85714 2.25787 2.85714 1.45455C2.85714 0.651222 2.21755 0 1.42857 0C0.639593 0 0 0.651222 0 1.45455C0 2.25787 0.639593 2.90909 1.42857 2.90909Z"
        fill={fill}
      />
      <path
        d="M1.42857 9.45455C2.21755 9.45455 2.85714 8.80332 2.85714 8C2.85714 7.19668 2.21755 6.54545 1.42857 6.54545C0.639593 6.54545 0 7.19668 0 8C0 8.80332 0.639593 9.45455 1.42857 9.45455Z"
        fill={fill}
      />
      <path
        d="M1.42857 16C2.21755 16 2.85714 15.3488 2.85714 14.5455C2.85714 13.7421 2.21755 13.0909 1.42857 13.0909C0.639593 13.0909 0 13.7421 0 14.5455C0 15.3488 0.639593 16 1.42857 16Z"
        fill={fill}
      />
    </svg>
  );
}
