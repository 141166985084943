export default function UserAvatar ({user, style, textStyle}) {
    return  user?.user_avatar ? (
        <img
          src={user.user_avatar}
          className="avatar rounded-full"
          alt={user?.user_name}
          style={style ?? {}}
        />
      ) : (
          <div className="avatar rounded-full" style={style ?? {}}>
          <h2 style={textStyle ?? {}}>
            {user?.user_name?.[0] ?? user?.name?.[0]}
            {user?.last_name?.[0]}
          </h2>
        </div>
      )
}