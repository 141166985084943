import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Loader,
  MainButton,
  PreviewCard,
  InputField,
  IconWrapper,
  BodyContainer,
  PrimaryHeading,
  HeaderContainer,
  ColorPicker,
  RightMainContainer,
  DeleteButton,
} from "commonUI";
import { BackArrow, SaveIcon } from "allIcons";
import {
  getUserSavePermission,
  isEqual,
  printError,
  titleCase,
  useSearch,
} from "helpers";
import { addStatus, deleteStatus, updateStatus } from "api/siteSettings/status";
import { useGetStatus } from "api/siteSettings/status";
import { useEffect } from "react";
import { useNotification } from "notification";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "client";
import { useUserPermissions } from "hooks/user";
import { ApiResponseType } from "enums";
import { t } from "i18next";

export default function StatusSetting() {
  const notification = useNotification();
  const navigate = useNavigate();
  const { web = null } = useSearch();
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();

  let { id, option } = useParams();
  const { isLoading, data } = useGetStatus(option);
  let statusData =
    data?.Data?.filter((element) => isEqual(element.id, id))[0] ?? {};
  const [inputValue, setInputValue] = useState(
    titleCase(isEqual(id, "add") ? "" : statusData?.status_title ?? "")
  );
  const [value, setValue] = useState(statusData?.color ?? "#0066ff");

  useEffect(() => {
    if (isLoading) return;
    if (!isEqual(id, "add") && data) {
      let statusData =
        data?.Data?.filter((element) => isEqual(element.id, id))[0] ?? {};
      setValue(statusData?.color);
      setInputValue(statusData?.status_title);
    }
  }, [data, isLoading, id]);

  const addMutation = useMutation(addStatus, {
    onSuccess: (data) => {
      if (!data?.Data?.id)
        return notification({
          type: "ERROR",
          message: data.message,
        });

      queryClient.invalidateQueries([`status-${option}`, web]);
      navigate(
        `/site-settings/status/${option}/${data?.Data?.id}${
          web ? `?web=${web}` : ""
        }`
      );
      notification({
        type: "success",
        message:  t("siteSetting.status.module.notification.added", {
          module: t(`siteSetting.status.${option.slice(0,option.length - 1)}`)
        }),
      });
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: "error",
        message: t("common.error.somethingWentWrongTryLater"),
      });
    },
  });

  const updateMutation = useMutation(updateStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([`status-${option}`, web]);
      notification({
        type: "success",
        message:  t("siteSetting.status.module.notification.updated", {
          module: t(`siteSetting.status.${option.slice(0,option.length - 1)}`)
        }),
      });
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: "error",
        message: t("common.error.somethingWentWrongTryLater"),
      });
    },
  });

  const deleteMutation = useMutation(deleteStatus, {
    onSuccess: (data) => {
      if (data?.StatusCode === ApiResponseType.SUCCESS) {
        queryClient.invalidateQueries([`status-${option}`, web]);
        notification({
          type: "success",
          message: t("siteSetting.status.module.notification.deleted", {
            module: t(`siteSetting.status.${option.slice(0,option.length - 1)}`)
          })
        });
        navigate(`/site-settings/status/${option}${web ? `?web=${web}` : ""}`);
      } else {
        notification({
          type: "error",
          message: printError(data?.Message),
        });
      }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: "error",
        message: t("common.error.somethingWentWrongTryLater"),
      });
    },
  });

  function handleSaveChanges() {
    if (!inputValue || !inputValue.trim() || !value || !value.trim()) {
      notification({
        type: "error",
        message: t("siteSetting.status.module.input.error.enterValue", {
          inputName: t(`siteSetting.status.module.input.${!inputValue || !inputValue.trim() ? "title" : "color"}`)
        })
      });
      return;
    }
    if (!isEqual(id, "add")) {
      updateMutation.mutate({
        id,
        status_title: inputValue,
        color: value,
        website_id: web,
      });
    } else {
      addMutation.mutate({
        status_title: inputValue,
        color: value,
        parent_type: option,
        system_status: 1,
        website_id: web,
      });
    }
  }
  return (
    <>
      <RightMainContainer>
        {(addMutation.isLoading ||
          updateMutation.isLoading ||
          deleteMutation.isLoading) && <Loader loader="block" />}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(
                  `/site-settings/status/${option}${web ? `?web=${web}` : ""}`
                )
              }
            />
            <PrimaryHeading>
              {isEqual(id, "add")
                ? t("siteSetting.status.module.addTitle", {
                  module: t(`siteSetting.status.${option.slice(0, option.length - 1)}`)
                })
                : inputValue}
            </PrimaryHeading>
          </IconWrapper>
          <IconWrapper>
            <DeleteButton
              show={
                !isEqual(id, "add") && remove_items_from_site_template && web
              }
              onDelete={() => {
                deleteMutation.mutate(id);
              }}
              deleteTitle={t("siteSetting.status.module.confirmDelete.forSite.title")}
              deleteButtonText={t("siteSetting.status.module.confirmDelete.forSite.buttonText")}
              deleteMessage={t("siteSetting.status.module.confirmDelete.forSite.message")}
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_items_to_site_template,
              editPermission: edit_item_details_in_site_template,
            }) ? (
              <MainButton onClick={handleSaveChanges}>
                <SaveIcon />{t("button.saveChanges")}
              </MainButton>
            ) : (
              ""
            )}
          </IconWrapper>
        </HeaderContainer>
        <BodyContainer>
          <div>
            <InputField
              label={t("siteSetting.status.module.input.title")}
              value={inputValue}
              setValue={setInputValue}
            />
          </div>
          {inputValue || value ? <PreviewCard title={inputValue} color={value} cross={true} /> : '' }
          <div>
            <InputField
              label={t("siteSetting.status.module.input.color")}
              value={value}
              setValue={setValue}
            />
          </div>
          <div>
            <ColorPicker value={value} setValue={setValue} />
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
