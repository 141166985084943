import { axiosInstance, printError, useSearch, useUtilities } from "helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addNewMember,
  deleteSingleMember,
  getMembers,
  updateSingleMember,
  getAllDefaultMembers,
  addNewDefaultMember,
  removeSingleDefaultMember,
  unuploadMembers,
  unLoadDefaultMembers,
} from "redux/reducers/usersModule/members";
import { useEffect } from "react";
import axios from "axios";
import { queryClient } from "client";
import { unloadSites } from "redux/reducers/sites";
import { unloadCompanies } from "redux/reducers/usersModule/companies";
import { t } from "i18next";

async function getAllMembers() {
  return axiosInstance
    .get("allmembers", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        user_type: "All",
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetAllMembers() {
  return useQuery(["members"], getAllMembers);
}

export function useMembers() {
  const { data, isLoading, error, isRefetching } = useGetAllMembers();
  const {
    dispatch,
    members: { members, loaded },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded || isRefetching) return;
    if (data) {
      dispatch(getMembers(data.Data));
    }
  }, [data, isLoading, loaded, isRefetching]);

  return { data: members, isLoading: isLoading || !loaded, error };
}

async function addMember({ data, callback }) {
  return axiosInstance
    .post("addwpslusers", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      ...data,
    })
    .then((res) => {
      callback && res.data.Data.id && callback(res.data.Data.id);
      return res.data;
    })
    .catch((err) => new Error(err));
}
export function useAddMember(redirect = "", module = t("users.module.user")) {
  const { web = null, backUrl } = useSearch();
  const { dispatch, navigate, notification } = useUtilities();

  function getBackURL(url) {
    if (backUrl) {
      return `${url}?backUrl=${backUrl}${web ? `&web=${web}` : ""}`;
    }
    return `${url}${web ? `?web=${web}` : ""}`;
  }
  let url = "";
  if (backUrl) {
    url = `${redirect}/${backUrl}`;
  }
  return useMutation(addMember, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        if (!web) {
          queryClient.invalidateQueries(["members"]);
          dispatch(unuploadMembers());
        }
        if (web) {
          queryClient.invalidateQueries(["siterelatedusers", web]);
          dispatch(unLoadDefaultMembers());
        }
        queryClient.invalidateQueries(["sitesData"]);
        dispatch(unloadSites());
        queryClient.invalidateQueries(["companies"]);
        dispatch(unloadCompanies());
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.userAdded", {
            userType: module
          }),
        });
        queryClient.invalidateQueries(["sitesData"]);
        dispatch(unloadSites());
        if (redirect) navigate(getBackURL(`${redirect}/${data.Data.id}`));
      } else {
        notification({
          type: "error",
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      console.log(err);
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function updateMember(data) {
  return axiosInstance
    .post("updatemember", {
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateMember(module) {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateMember, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateSingleMember(data.Data));
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.userUpdated", {
            userType: module
          }),
        });
        queryClient.invalidateQueries(["companies"]);
        dispatch(unloadCompanies());
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

async function deleteMember(data) {
  return axiosInstance
    .post("delmember", {
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => ({ ...res.data, ...data }))
    .catch((err) => new Error(err));
}

export function useDeleteMember(redirect, module) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteMember, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteSingleMember(data.user_id));
        queryClient.invalidateQueries(["sitesData"]);
        dispatch(unloadSites());
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.userDeleted", {
            userType: module
          }),
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

async function getDefaultMembers() {
  return axiosInstance
    .post("getdefaultuser", {
      apikey: localStorage.getItem("apikey"),
      website_id: null,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetDefaultMembers() {
  return useQuery(["defaultMembers"], getDefaultMembers);
}

export function useDefaultMembers() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetDefaultMembers();

  const {
    dispatch,
    members: {
      defaultState: { members, loaded, website_id },
    },
  } = useUtilities();
  useEffect(() => {
    if (web) return;
    if (isLoading) return;
    if (data) {
      dispatch(
        getAllDefaultMembers({
          data: data.Data,
          website_id: web,
        })
      );
    }
  }, [data, isLoading, loaded, web]);

  return {
    data: members,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
  };
}

async function addDefaultMember({ data }) {
  return axiosInstance
    .post("adddefaulttemplateuser", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultMember() {
  const { notification, dispatch } = useUtilities();
  return useMutation(addDefaultMember, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addNewDefaultMember(data.Data));
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.addedDefaultMember"),
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function removeDefaultMember(id) {
  return axiosInstance
    .post("defaultuserremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useRemoveDefaultMember() {
  const { notification, dispatch } = useUtilities();
  return useMutation(removeDefaultMember, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(removeSingleDefaultMember(data));
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.removedDefaultMember"),
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function addUsertoWP({ web_url, ...data }) {
  return axios.post(web_url + "/wp-json/dl/v1/createwpslteammembers", {
    ...data,
  });
}

export function useAddUsertoWP() {
  return useMutation(addUsertoWP, {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (err) => {
      console.log(err);
    },
  });
}

async function addUsertoSignleWeb(data) {
  return axiosInstance
    .post("addmemberfromwebsite", {
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddUsertoSingleWeb() {
  const { notification, dispatch } = useUtilities();
  return useMutation(addUsertoSignleWeb, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(updateSingleMember(data.Data[0]));
        queryClient.invalidateQueries(["members"]);
        dispatch(unuploadMembers());
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.addedDefaultMember"),
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function removeMemberFromWeb(data) {
  return axiosInstance
    .post("delmemberfromwebsite", {
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => ({ ...res.data, id: data.user_id }))
    .catch((err) => new Error(err));
}

export function useRemoveMemberFromWeb() {
  const { web = null } = useSearch();
  const { notification, dispatch } = useUtilities();
  return useMutation(removeMemberFromWeb, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(removeSingleDefaultMember(data.Data[0]));
        if (web) {
          queryClient.invalidateQueries(["members"]);
          dispatch(unuploadMembers());
        }
        notification({
          type: "success",
          message: t("users.teamMember.api.notification.removedDefaultMember"),
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function siteRelatedUsers(website_id) {
  return axiosInstance
    .post("siterelatedusers", {
      apikey: localStorage.getItem("apikey"),
      website_id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetSiteRelatedUsers(web) {
  return useQuery(["siterelatedusers", web], () => siteRelatedUsers(web));
}

export function useSiteRelateUsers() {
  const { web = null } = useSearch();
  const { data, isLoading, error, isRefetching } = useGetSiteRelatedUsers(web);

  const {
    dispatch,
    members: {
      defaultState: { members, loaded, website_id },
    },
  } = useUtilities();
  useEffect(() => {
    if (isLoading || (website_id === web && loaded) || isRefetching) return;
    if (data) {
      dispatch(
        getAllDefaultMembers({
          data: data.Data ?? [],
          website_id: web,
        })
      );
    }
  }, [data, isLoading, website_id, web, isRefetching]);

  return {
    data: members,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
  };
}
