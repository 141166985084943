import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, FilesIcon } from "allIcons";
import {
  AddFromTemplateLibrary,
  BodyContainer,
  FiltersList,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PopOver,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
} from "commonUI";
import {
  useAddDefaultContentRequest,
  useContentRequests,
  useDefaultContentRequest,
  useDeleteDefaultContentRequest,
} from "api/templateLibrary/contentRequests";
import { isEqual, search } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function ContentRequestTemplates() {
  const {
    data: defaultContentRequest,
    isLoading: loadingDefaultContentRequest,
  } = useDefaultContentRequest();
  const { data: contentRequests, isLoading: loadingContentRequest } =
    useContentRequests();
  const { mutate: addDefaultContentRequest, isLoading: addingDefaultContent } =
    useAddDefaultContentRequest();
  const {
    mutate: deleteDefaultContentRequest,
    isLoading: deletingDefaultContent,
  } = useDeleteDefaultContentRequest();
  const [contentlist, setContentlist] = useState([]);
  const [addTemplatePopup, setAddTemplatePopup] = useState(false);
  useEffect(() => {
    setContentlist(
      defaultContentRequest.filter((_) => isEqual(_.default_tem, 1))
    );
  }, [defaultContentRequest]);

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const { remove_items_from_site_template } = useUserPermissions();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchResult(
      contentlist.filter((item) => search(item.title, defferedSearch))
    );
  }, [defferedSearch, contentlist]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        contentlist
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {addingDefaultContent || deletingDefaultContent ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <PrimaryHeading>Content Requests</PrimaryHeading>
          <div className="relative">
            <MainButton onClick={() => setAddTemplatePopup(true)}>
              <AddIcon />
              Add
            </MainButton>
            <PopOver
              open={addTemplatePopup}
              onClose={() => setAddTemplatePopup(false)}
            >
              <AddFromTemplateLibrary
                data={contentRequests
                  .filter((_) => !isEqual(_.default_tem, 1))
                  .map((_) => ({
                    ..._,
                    icon: <FilesIcon />,
                    onClick: () => addDefaultContentRequest(_.id),
                  }))}
                emptyTemplate={{
                  label: "Content Request",
                  linkLabel: "Content Request",
                  link: "/template-library/content-request-templates/add",
                }}
                isLoading={loadingContentRequest}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingDefaultContentRequest ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Content Request"
              linkLabel="Content Request"
              onClick={() => setAddTemplatePopup(true)}
            />
          ) : (
            Children.toArray(
              searchResult.map((ele, index) => {
                return (
                  <ListCard
                    key={index}
                    editIcon={false}
                    data={ele.title}
                    onCross={() => {
                      deleteDefaultContentRequest(ele.id);
                    }}
                    deleteIcon
                    crossIcon={remove_items_from_site_template}
                    showModalonCross
                    icon={
                      <ListIconWrapper>
                        <FilesIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle="Remove This Template?"
                    deleteButtonText="Confirm"
                    deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
