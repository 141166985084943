import React, { useEffect, useState } from "react";
import { SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  LimitReachedCard,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  SelectField,
  ToggleCard,
} from "commonUI";
import { useSmtp, useUpdateSmtpSetting } from "hooks/smtp";
import { isEqual, parse, useSearch, useUtilities } from "helpers";
import useUserPlan from "hooks/plan";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function SMTP() {
  const { data, isLoading } = useSmtp();
  const { web = null } = useSearch();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const whiteLabelAllowed = isEqual(wpslPlan.white_label, 1);
  const { mutate: updateSmtp, isLoading: updatingSmtp } =
    useUpdateSmtpSetting();
  const { notification } = useUtilities();
  const [values, setValues] = useState({
    smtp_host: "",
    smtp_from_email: "",
    smtp_from_name: "",
    encryption: "None",
    smtp_port: "",
    auto_tls: false,
    authentication: true,
    smtp_username: "",
    smtp_password: "",
    smtp_custom: false,
  });

  useEffect(() => {
    if (!data || isLoading) return;
    const smtp = data?.find((item) => item.website_id === (web || null));
    if (!smtp) return;
    setValues(parse(smtp.smtp_settings) || values);
  }, [data, isLoading, web]);

  const handleSaveChanges = () => {
    if (values.smtp_custom) {
      if (!values.smtp_host || !values.smtp_host?.trim()) {
        notification({
          message: t("siteSetting.smtp.useCustomSetting.input.error.invalidSmtpHost"),
          type: "error",
        });
        return;
      }
      if (
        !values.smtp_from_email ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.smtp_from_email)
      ) {
        notification({
          message: t("siteSetting.smtp.useCustomSetting.input.error.invalidSmtpFromEmail"),
          type: "error",
        });
        return;
      }
      if (!values.smtp_from_name?.trim()) {
        notification({
          message: t("siteSetting.smtp.useCustomSetting.input.error.requiredSmtpFromName"),
          type: "error",
        });
        return;
      }
      if (!String(values.smtp_port)?.trim()) {
        notification({
          message: t("siteSetting.smtp.useCustomSetting.input.error.requiredSmtpPort"),
          type: "error",
        });
        return;
      }
      if (!values.smtp_username?.trim()) {
        notification({
          message: t("siteSetting.smtp.useCustomSetting.input.error.requiredSmtpUsername"),
          type: "error",
        });
        return;
      }
      if (!values.smtp_password?.trim()) {
        notification({
          message: t("siteSetting.smtp.useCustomSetting.input.error.requiredSmtpPassword"),
          type: "error",
        });
        return;
      }
    }
    updateSmtp({
      website_id: web || null,
      data: { ...values, smtp_port: String(values.smtp_port)?.trim() },
    });
  };
  const { add_new_items_to_site_template, edit_item_details_in_site_template } =
    useUserPermissions();

  return (
    <RightMainContainer>
      <Loader show={updatingSmtp} loader="block" />
      <HeaderContainer>
        <PrimaryHeading className="leading-6">{t("siteSetting.smtp.title")}</PrimaryHeading>
        {(!whiteLabelAllowed ||
          !add_new_items_to_site_template ||
          !edit_item_details_in_site_template) &&
        !loadingPlans ? (
          ""
        ) : (
          <MainButton
            onClick={handleSaveChanges}
            disabled={updatingSmtp || isLoading}
          >
            <SaveIcon />{t("button.saveChanges")}
          </MainButton>
        )}
      </HeaderContainer>
      <BodyContainer>
        {!whiteLabelAllowed && !loadingPlans ? (
          <LimitReachedCard
            show
            title={t("siteSetting.smtp.accessLimit.title")}
            description={t("siteSetting.smtp.accessLimit.description")}
          />
        ) : (
          <>
            <ToggleCard
              label={t("siteSetting.smtp.useCustomSetting.title")}
              labelClass="!mt-0"
              description={t("siteSetting.smtp.useCustomSetting.description")}
              value={values.smtp_custom}
              setValue={(value) => setValues({ ...values, smtp_custom: value })}
              className="mb-3"
            />
            {values.smtp_custom ? (
              <>
                <InputField
                  label={t("siteSetting.smtp.useCustomSetting.input.smtpHost.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.smtpHost.placeholder")}
                  value={values.smtp_host}
                  setValue={(value) =>
                    setValues({ ...values, smtp_host: value })
                  }
                  inputDivClass="mb-0"
                  className="mb-3"
                />
                <InputField
                  label={t("siteSetting.smtp.useCustomSetting.input.smtpFromEmail.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.smtpFromEmail.placeholder")}
                  value={values.smtp_from_email}
                  setValue={(value) =>
                    setValues({ ...values, smtp_from_email: value })
                  }
                  inputDivClass="mb-0"
                  className="mb-3"
                />
                <InputField
                  label={t("siteSetting.smtp.useCustomSetting.input.smtpFromName.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.smtpFromName.placeholder")}
                  value={values.smtp_from_name}
                  setValue={(value) =>
                    setValues({ ...values, smtp_from_name: value })
                  }
                  inputDivClass="mb-0"
                  className="mb-3"
                />
                <SelectField
                  label={t("siteSetting.smtp.useCustomSetting.input.encryption.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.encryption.placeholder")}
                  value={values.encryption}
                  setValue={(value) =>
                    setValues({ ...values, encryption: value })
                  }
                  inputDivClass="mb-0"
                  popupLabel={t("siteSetting.smtp.useCustomSetting.input.encryption.label")}
                  className="mb-3"
                >
                  <option value="None">{t("siteSetting.smtp.useCustomSetting.input.encryption.none")}</option>
                  <option value="TLS">{t("siteSetting.smtp.useCustomSetting.input.encryption.tls")}</option>
                  <option value="SSL">{t("siteSetting.smtp.useCustomSetting.input.encryption.ssl")}</option>
                </SelectField>
                <InputField
                  label={t("siteSetting.smtp.useCustomSetting.input.smtpPort.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.smtpPort.placeholder")}
                  value={values.smtp_port}
                  setValue={(value) =>
                    setValues({ ...values, smtp_port: value })
                  }
                  inputDivClass="mb-0"
                  className="mb-3"
                />
                <div>
                  <SecondaryHeading>{t("siteSetting.smtp.useCustomSetting.input.autoTLS.label")}</SecondaryHeading>
                  <ToggleCard
                    value={values.auto_tls}
                    setValue={(value) =>
                      setValues({ ...values, auto_tls: value })
                    }
                    inputDivClass="mb-0"
                    className="mb-3"
                  />
                </div>
                <div className="mt-3 mb-3">
                  <SecondaryHeading>{t("siteSetting.smtp.useCustomSetting.input.authentication.label")}</SecondaryHeading>
                  <ToggleCard
                    value={values.authentication}
                    setValue={(value) =>
                      setValues({ ...values, authentication: value })
                    }
                    inputDivClass="mb-0"
                    className="mb-3"
                  />
                </div>
                <InputField
                  label={t("siteSetting.smtp.useCustomSetting.input.smtpUsername.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.smtpUsername.placeholder")}
                  value={values.smtp_username}
                  setValue={(value) =>
                    setValues({ ...values, smtp_username: value })
                  }
                  inputDivClass="mb-0"
                  className="mb-3"
                />
                <InputField
                  label={t("siteSetting.smtp.useCustomSetting.input.smtpPassword.label")}
                  placeholder={t("siteSetting.smtp.useCustomSetting.input.smtpPassword.placeholder")}
                  value={values.smtp_password}
                  setValue={(value) =>
                    setValues({ ...values, smtp_password: value })
                  }
                  inputDivClass="mb-0"
                  className="mb-3"
                  type="password"
                />
              </>
            ) : (
              ""
            )}
          </>
        )}
      </BodyContainer>
    </RightMainContainer>
  );
}
