import { useQuery, useMutation } from "@tanstack/react-query";
import { NotificationType } from "enums";
import { axiosInstance, printError, useSearch, useUtilities } from "helpers";
import { t } from "i18next";
import { useEffect } from "react";
import {
  setInterfaceSettingAction,
  updateInterfaceSettingAction,
} from "redux/reducers/siteSettings/interface";

async function getInterfaceSetting(website_id) {
  return axiosInstance
    .get("allfeaturesettings", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        tab_hint: "interface",
        website_id: website_id ? website_id : JSON.stringify(website_id),
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

function useGetInterfaceSetting() {
  const { web = null } = useSearch();
  return useQuery(["interfaceSettings", web], () => getInterfaceSetting(web));
}

export function useInterface() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetInterfaceSetting();
  const {
    dispatch,
    interfaceSetting: { interfaceSetting, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (web !== website_id || !loaded)) {
      dispatch(
        setInterfaceSettingAction({
          interface_settings: data.Data?.[0] ?? {},
          website_id: web,
        })
      );
    }
  }, [data, isLoading, error, loaded, web]);

  return {
    data: interfaceSetting,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
    website_id,
  };
}

async function updateInterfaceSetting(data) {
  return axiosInstance
    .post("updatefeaturesettings", {
      ...data,
      tab_hint: "interface",
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateInterfaceSetting() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateInterfaceSetting, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateInterfaceSettingAction(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: t("siteSetting.interface.api.notification.updated")
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(err.message),
      });
    },
  });
}
