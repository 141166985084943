import { Children, useDeferredValue, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AddIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  LoadingListCards,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
  Loader,
  FiltersList,
} from "commonUI";
import {
  useContentRequestsItem,
  useAddContentRequestItem,
  useDeleteContentRequestItem,
} from "api/templateLibrary/contentRequestsItem";
import { contentRequestItemType, parse, search, useUtilities } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useTags } from "api/siteSettings/Tags";
import { useContentRequests } from "api/templateLibrary/contentRequests";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function ContentRequestItems() {
  const { data: contentRequestsItem, isLoading: loadingContentRequestItem } =
    useContentRequestsItem();
  const { mutate: deleteContentRequestItem, isLoading: isDeleting } =
    useDeleteContentRequestItem();
  const { mutate: addContentRequestItem, isLoading: isAdding } =
    useAddContentRequestItem(false);
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const { data: contentRequests, isLoading: loadingContentRequest } =
    useContentRequests();
  const { notification } = useUtilities();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchResult(
      contentRequestsItem.filter((item) => search(item.title, defferedSearch))
    );
  }, [defferedSearch, contentRequestsItem]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        contentRequestsItem
      )
    );
  };
  return (
    <>
      <RightMainContainer>
        {isDeleting || isAdding ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <PrimaryHeading>Content Request Items</PrimaryHeading>
          {add_new_templates ? (
            <Link to="add">
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingContentRequestItem ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Content Request Item"
              link={add_new_templates ? "add" : ''}
              linkLabel="Content Request Item"
            />
          ) : (
            Children.toArray(
              searchResult.map((item, index) => {
                return (
                  <ListCard
                    data={item.title}
                    duplicate={add_new_templates}
                    editIcon={edit_templates}
                    link={edit_templates ? `/template-library/content-request-items/${item.id}` : ''}
                    strictLink={true}
                    onDuplicate={() =>
                      addContentRequestItem({
                        ...item,
                        title: `Copy of ${item.title}`,
                        hint: item.hint || item.type,
                        instructions: item.instructions || item.description,
                        requested_from: parse(item.requested_from),
                      })
                    }
                    deleteIcon={!delete_templates}
                    onDelete={() => {
                      const haveContentRequest =
                        contentRequests.findIndex((ele) => {
                          return (
                            parse(ele.contentitems).findIndex(
                              (ele) => String(ele.id) === String(item.id)
                            ) !== -1
                          );
                        }) !== -1;
                      if (haveContentRequest) {
                        notification({
                          type: "error",
                          message:
                            'This item is being used in one/many "Content Request".',
                        });
                        return;
                      }
                      deleteContentRequestItem(item.id);
                    }}
                    icon={
                      <ListIconWrapper>
                        {contentRequestItemType[item.hint || item.type]}
                      </ListIconWrapper>
                    }
                    deleteTitle="Delete This Template?"
                    deleteButtonText="Confirm Delete"
                    deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
