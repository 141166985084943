import { useMutation } from "@tanstack/react-query";
import { updateBrandingAndColors } from "api/siteSettings/brandingAndColors";
import { useGetFeatureModuleSetting } from "api/siteSettings/modularApi";
import { ApiResponseType, NotificationType } from "enums";
import { FeatureModules } from "enums/modules";
import { isEmpty, useSearch, useUtilities } from "helpers";
import { t } from "i18next";
import { useEffect } from "react";
import {
  setBrandingAndColors,
  updateBrandingAndColorsAction,
} from "redux/reducers/siteSettings/brandingAndColors"; 

export function useBrandingAndColors() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetFeatureModuleSetting(
    FeatureModules.BRANDS_AND_COLORING
  );
  const {
    dispatch,
    brandingAndColors: { brandingAndColors, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (web !== website_id || !loaded || isEmpty(brandingAndColors) || !brandingAndColors)) {
      dispatch(
        setBrandingAndColors({
          brandingAndColors: data.Data[0],
          website_id: web,
        })
      );
    }
  }, [data, isLoading, error, loaded, web, brandingAndColors]);

  return {
    data: brandingAndColors,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
  };
}

export function useUpdateBrandingAndColors() {
  const { web = null } = useSearch();
  const { notification, dispatch } = useUtilities();
  return useMutation(
    (params) =>
      updateBrandingAndColors({
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        website_id: web,
        system_status: 0,
        tab_hint: FeatureModules.BRANDS_AND_COLORING,
        ...params,
      }),
    {
      onSuccess: (data) => {
        console.log(data);
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          dispatch(
            updateBrandingAndColorsAction({
              brandingAndColors: data.Data,
              website_id: web,
            })
          );
          notification({
            type: NotificationType.SUCCESS,
            message: t("siteSetting.brandingAndColors.api.notification.updated"),
          });
        } else {
          notification({
            type: NotificationType.ERROR,
            message: t("siteSetting.brandingAndColors.api.notification.updateFailed"),
          });
        }
      },
      onError: (error) => {
        console.log(error);
        notification({
          type: NotificationType.ERROR,
          message: t("siteSetting.brandingAndColors.api.notification.updateFailed"),
        });
      },
    }
  );
}
