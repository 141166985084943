import { CrossIcon } from "allIcons";
import { LoadingCard } from "commonUI";

export default function PreviewCard({
  onCross,
  cardClass,
  label,
  isLoading,
  color,
  title,
  icon,
  className,
  description,
  dataIcon=null,
  buttonStyle={},
  textStyle={},
}) {
  return (
    <>
      <div className={`mb-2 mt-2 ${className ?? ""}`}>
        <label htmlFor="" className="secondary-heading block mb-2">
          {label ?? ""}
        </label>
        {description ? <p className="body-text mt-1-5 mb-1-5 text-theme-secondary">{description}</p> : ""}
        {isLoading ? (
          <LoadingCard className="w-40" />
        ) : (
          <div className={`status-card-preview ${cardClass ?? ""} ${dataIcon ? "status-card-preview-dataIcon" : ""}`} style={buttonStyle}>
            {color ? (
              <div
                className={`status-card-color-circle `}
                style={{ backgroundColor: `${color}` }}
              ></div>
            ) : (
              ""
            )}
            <div className=" flex items-center w-full justify-between">
              <div className="flex gap-5 items-center">
                {dataIcon}
                <h2 className="secondary-heading capitalize" style={textStyle}>{title}</h2>
              </div>
              {icon ? (
                <div className="ml-3">
                  <CrossIcon onClick={onCross} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
