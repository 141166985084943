export default function IconContainer({
  children,
  width = "19px",
  height = "19px",
  className = "",
  ...props
}) {
  return (
    <div
      className={`flex items-center justify-center ${className}`}
      style={{ width, height }}
      {...props}
    >
      {children}
    </div>
  );
}
