import { SaveIcon } from "allIcons";
import {
  useInterface,
  useUpdateInterfaceSetting,
} from "api/siteSettings/interfaceSetting";
import {
  BodyContainer,
  HeaderContainer,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  ToggleCard,
} from "commonUI";
import { parse, useSearch } from "helpers";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";
import { useEffect, useState } from "react";

export default function Interface() {
  const [values, setValues] = useState({
    open_main_window: "Same Docked Window",
    open_child_from_dock: "Same Docked Window",
    open_child_from_floating: "Same Floating Window",
    show_comment_outline: true,
  });
  const { web = null } = useSearch();
  const { data, website_id, isLoading: loadingInterface } = useInterface();
  const { mutate: updateInterface, isLoading: updatinInterface } =
    useUpdateInterfaceSetting();

  const { edit_item_details_in_site_template } = useUserPermissions();

  useEffect(() => {
    if (data?.interface_settings && web === website_id) {
      const settings = parse(data.interface_settings);
      setValues(settings);
    }
  }, [data, website_id]);

  const handleSave = () => {
    updateInterface({
      interface_settings: values,
      system_status: data.system_status,
      website_id: data?.website_id || web,
      apiKey: data?.apiKey || localStorage.getItem("apikey"),
      added_by: data?.added_by || localStorage.getItem("user_id"),
    });
  };

  return (
    <>
      <Loader show={updatinInterface} loader="block" />
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>{t("siteSetting.interface.title")}</PrimaryHeading>
          {edit_item_details_in_site_template ? <MainButton
            onClick={handleSave}
            disabled={updatinInterface || loadingInterface}
          >
            <SaveIcon />{t("button.saveChanges")}
          </MainButton> : ''}
        </HeaderContainer>
        <BodyContainer>
          <SelectField
            label={t("siteSetting.interface.openAllItemsByDefaultIn")}
            placeholder={t("siteSetting.interface.option.sameDockedWindow")}
            value={values.open_main_window}
            setValue={(value) =>
              setValues({ ...values, open_main_window: value })
            }
          >
            <option value="Same Docked Window">{t("siteSetting.interface.option.sameDockedWindow")}</option>
            <option value="Same Floating Window">{t("siteSetting.interface.option.sameFloatingWindow")}</option>
            <option value="New Floating Window">{t("siteSetting.interface.option.newFloatingWindow")}</option>
          </SelectField>
          <SelectField
            label={t("siteSetting.interface.openAllItemsFromDockedWindowIn")}
            placeholder={t("siteSetting.interface.option.sameDockedWindow")}
            value={values.open_child_from_dock}
            setValue={(value) =>
              setValues({ ...values, open_child_from_dock: value })
            }
          >
            <option value="Same Docked Window">{t("siteSetting.interface.option.sameDockedWindow")}</option>
            <option value="Same Floating Window">{t("siteSetting.interface.option.sameFloatingWindow")}</option>
          </SelectField>
          <SelectField
            label={t("siteSetting.interface.openAllItemsFromFloatingWindowIn")}
            placeholder={t("siteSetting.interface.option.sameFloatingWindow")}
            value={values.open_child_from_floating}
            setValue={(value) =>
              setValues({
                ...values,
                open_child_from_floating: value,
              })
            }
          >
            <option value="Same Floating Window">{t("siteSetting.interface.option.sameFloatingWindow")}</option>
            <option value="New Floating Window">{t("siteSetting.interface.option.newFloatingWindow")}</option>
          </SelectField>

          <ToggleCard
            label={t("siteSetting.interface.showCommentsOutline")}
            value={values.show_comment_outline}
            setValue={(value) =>
              setValues({ ...values, show_comment_outline: value })
            }
          />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
