import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, TutorialIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  Loader,
  LoadingListCards,
  TemplateNotFound,
  FiltersList,
} from "commonUI";
import { Link } from "react-router-dom";
import {
  useTemplateTutorials,
  useAddTemplateTutorial,
  useDeleteTemplateTutorial,
} from "api/templateLibrary/tutorials";
import { parse, search } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function Tutorials() {
  const {
    data: tutorials,
    isLoading: tutorialIsLoading,
    error,
  } = useTemplateTutorials();

  const deleteTutorialTemplateMutation = useDeleteTemplateTutorial();
  const duplicateMutation = useAddTemplateTutorial(true);

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    setSearchResult(
      tutorials.filter((task) => search(task.title, defferedSearch))
    );
  }, [defferedSearch, tutorials]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        tutorials
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {deleteTutorialTemplateMutation.isLoading ||
        duplicateMutation.isLoading ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <PrimaryHeading>Tutorials</PrimaryHeading>
          {add_new_templates ? (
            <Link to="add">
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {Children.toArray(
            tutorialIsLoading ? (
              <LoadingListCards />
            ) : searchResult.length > 0 ? (
              searchResult.map((tutorial, index) => {
                return (
                  <ListCard
                    data={tutorial.title}
                    duplicate={add_new_templates}
                    editIcon={edit_templates}
                    link={
                      edit_templates
                        ? `/template-library/tutorials/${tutorial.id}`
                        : ""
                    }
                    strictLink={true}
                    onDuplicate={(data) => {
                      duplicateMutation.mutate({
                        title: `Copy of ${tutorial.title}`,
                        video_link: tutorial?.[
                          tutorial.video_link ? "video_link" : "video_url"
                        ]?.includes('"')
                          ? parse(
                              tutorial[
                                tutorial.video_link ? "video_link" : "video_url"
                              ]
                            )
                          : tutorial[
                              tutorial.video_link ? "video_link" : "video_url"
                            ],
                        message: tutorial.message,
                        tags: parse(tutorial.tags),
                      });
                    }}
                    deleteIcon={!delete_templates}
                    onDelete={() => {
                      deleteTutorialTemplateMutation.mutate(tutorial.id);
                    }}
                    icon={
                      <ListIconWrapper>
                        <TutorialIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle="Delete This Template?"
                    deleteButtonText="Confirm Delete"
                    deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                  />
                );
              })
            ) : (
              <TemplateNotFound
                label="Tutorial"
                linkLabel="Tutorial"
                link={
                  add_new_templates ? "/template-library/tutorials/add" : ""
                }
              />
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
