import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  tags: [],
  loaded: false,
  website_id: null,
  system_status: null,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    getTags: (state, action) => {
      const { tags, website_id, system_status } = action.payload;
      state.tags = tags;
      state.loaded = true;
      state.website_id = website_id;
      state.system_status = system_status;
    },
    addSingleTag: (state, action) => {
      state.tags.push(action.payload);
    },
    updateSingleTag: (state, action) => {
      state.tags = state.tags.map((tag) => {
        if (isEqual(tag.id, action.payload.tag_id ?? action.payload.id)) {
          return {
            ...tag,
            tag_title: action.payload.tag_title,
            color: action.payload.color,
          };
        }
        return { ...tag };
      });
    },
    deleteSingleTag: (state, action) => {
      state.tags = state.tags.filter((tag) => tag.id != action.payload.id);
    },
  },
});

export const { getTags, updateSingleTag, addSingleTag, deleteSingleTag } =
  tagsSlice.actions;

export default tagsSlice.reducer;
