import { getSmtpSetting, updateSmtpSetting } from "api/siteSettings/smtp";
import { useMutation, useQuery } from "@tanstack/react-query";
import { printError, useSearch, useUtilities } from "helpers";
import { useEffect } from "react";
import { setSmtpSetting, updateSmtp } from "redux/reducers/siteSettings/smtp";
import { ApiResponseType, NotificationType } from "enums";
import { t } from "i18next";

export function useGetSmtpSetting() {
  const { web = null } = useSearch();
  return useQuery(["smtp", web], () => getSmtpSetting(web));
}

export function useSmtp() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetSmtpSetting();
  const {
    dispatch,
    smtp: { smtp, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (!loaded || website_id !== web)) {
      dispatch(
        setSmtpSetting({
          smtp: data.Data,
          website_id: web,
        })
      );
    }
  }, [data, isLoading, error, loaded]);

  return {
    data: smtp,
    isLoading: isLoading || !loaded,
    error,
  };
}

export function useUpdateSmtpSetting() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateSmtpSetting, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(updateSmtp(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: t("siteSetting.smtp.api.notification.updated"),
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(err.message),
      });
    },
  });
}
