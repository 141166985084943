import {
  ListCard,
  RightMainContainer,
  ListIconWrapper,
  GridCard,
  LoadingListCards,
  LoadingGridCards,
  InputField,
  SecondaryHeading,
  MainButton,
  LimitReachedCard,
} from "commonUI";
import SiteCard from "./SiteCard";
import {
  GridIcon,
  ListIcon,
  SiteIcon,
  DefaultSiteTemplateIcon,
} from "allIcons";
import { useDeferredValue, useEffect, useState } from "react";
import { useSites } from "api/sites";
import ApiKeyCard from "./ApiKeyCard";
import { Link } from "react-router-dom";
import { useUserPermissions } from "hooks/user";
import { isEqual } from "helpers";
import useUserPlan from "hooks/plan";
import { libraryItemSorting } from "filterAndSort/templateLibrary";
import { t } from "i18next";

export default function SiteTab() {
  const [viewMode, setViewMode] = useState("grid");
  const { data, isLoading } = useSites();
  const [sites, setSites] = useState(data?.site_details?.total_sites ?? []);
  const [searchText, setSearchText] = useState("");
  const defferedSearchText = useDeferredValue(searchText);
  const { view_site_template, view_sites } = useUserPermissions();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const siteTemplateAllowed = isEqual(wpslPlan.site_template, 1);

  useEffect(() => {
    if (isLoading) return;
    setSites(data?.site_details?.total_sites ?? []);
  }, [data]);

  useEffect(() => {
    if (isLoading) return;
    if (defferedSearchText === "") {
      setSites(data?.site_details?.total_sites ?? []);
      return;
    }
    const tempSiteState = data?.site_details?.total_sites.filter((site) => {
      return site.web_name
        .toLowerCase()
        .includes(defferedSearchText.toLowerCase());
    });
    setSites(tempSiteState);
  }, [defferedSearchText]);

  const applySorting = (order, orderBy) => {
    setSites((prevSites) =>
      libraryItemSorting(order, orderBy, prevSites, "web_name")
    );
  };
  return (
    <>
      <RightMainContainer className="bg-transparent">
        <div className="flex flex-row justify-between gap-15">
          <SiteCard
            isLoading={isLoading}
            sites={{
              description: t(
                isEqual(data?.site_details?.sites_limit, "1000000000")
                  ? "sites.siteUsageDetail.unlimitedSites.body"
                  : "sites.siteUsageDetail.body",
                {
                  currentSites: data?.site_details?.total_used_site,
                  totalSites: data?.site_details?.sites_limit,
                  usagePercentage: Number(
                    data?.site_details?.site_percentage
                  ).toFixed(2),
                }
              ),
              line: data?.site_details?.site_percentage,
            }}
            storage={{
              description: t(
                isEqual(data?.site_details?.sites_limit, "1000000000")
                  ? "sites.storageUsageDetail.unlimitedStorage.body"
                  : "sites.storageUsageDetail.body",
                {
                  currentStorage: data?.storage_details?.total_used_storage,
                  totalStorage: data?.storage_details?.storage_limit,
                  usagePercentage: Number(
                    data?.storage_details?.storage_percentage
                  ).toFixed(2),
                }
              ),
              line: data?.storage_details?.storage_percentage,
            }}
          />
          <ApiKeyCard
            isLoading={isLoading}
            apikey={localStorage.getItem("apikey") ?? ""}
          />
        </div>
        <div className="flex flex-col p-5 rounded-5 bg-white mt-3">
          <SecondaryHeading>
            {t("siteTemplate.manageSiteTemplate.title")}
          </SecondaryHeading>
          <p className="body-text text-theme-placeholder mt-1">
            {t("siteTemplate.manageSiteTemplate.description")}
          </p>
          {view_site_template && siteTemplateAllowed && !loadingPlans ? (
            <Link to="/site-settings">
              <MainButton className={"w-fit"}>
                <DefaultSiteTemplateIcon />
                &nbsp;{t("siteTemplate.manageSiteTemplate.button")}
              </MainButton>
            </Link>
          ) : (
            <LimitReachedCard
              show
              title={t("plan.siteTemplate.notIncluded.title")}
              description={t("plan.siteTemplate.notIncluded.description")}
            />
          )}
        </div>
        {view_sites ? (
          <div className="bg-white mt-3 rounded-5">
            <div className="px-5 mt-5">
              <InputField
                placeholder={t("input.search.placeholder")}
                value={searchText}
                setValue={(value) => setSearchText(value)}
                searchIcon={true}
                sort
                sortOptions={[
                  {
                    label: t("sort.options.dateAdded"),
                    value: "date-added",
                  },
                  {
                    label: t("sort.options.storageSize"),
                    value: "single_web_storage",
                  },
                  {
                    label: t("sort.options.alphabeticalByTitle"),
                    value: "alphabetical-by-title",
                  },
                ]}
                applySorting={applySorting}
              />
            </div>
            <div className="flex justify-end items-center pt-1 px-5">
              <div className="flex flex-row gap-10 items-center justify-center">
                <p className="body-text">{t("sites.list.viewMode")}</p>
                <GridIcon
                  fill={viewMode === "grid" ? "#4C5866" : "#A3B0C2"}
                  onClick={() => setViewMode("grid")}
                />
                <ListIcon
                  fill={viewMode === "list" ? "#4C5866" : "#A3B0C2"}
                  onClick={() => setViewMode("list")}
                />
              </div>
            </div>
            <div
              className={`flex ${
                viewMode === "list" ? "flex-col" : "flex-row flex-wrap gap-15"
              } px-4 pt-3 pb-4 bg-white `}
            >
              {sites.length > 0 ? (
                sites.map((iner) => {
                  if (viewMode === "list") {
                    return (
                      <ListCard
                        showTitle
                        data={iner.web_name}
                        key={iner.id}
                        index={iner.id}
                        link={`/site-settings?web=${iner.dl_website_id}`}
                        strictLink={true}
                        deleteIcon={true}
                        className={"mb-1 mt-1"}
                        icon={
                          <ListIconWrapper>
                            <SiteIcon />
                          </ListIconWrapper>
                        }
                        deleteMessage={t("sites.deleteConfirmation.text")}
                        deleteConfirmations={t(
                          "sites.deleteConfirmation.button"
                        )}
                      />
                    );
                  } else {
                    return (
                      <GridCard
                        data={iner.web_name}
                        key={iner.id}
                        index={iner.id}
                        link={`/site-settings?web=${iner.dl_website_id}`}
                        strictLink={true}
                        icon={
                          <ListIconWrapper>
                            <SiteIcon />
                          </ListIconWrapper>
                        }
                        image={require("assets/SiteImage.png")}
                        deleteMessage={t("sites.deleteConfirmation.text")}
                        deleteConfirmations={t(
                          "sites.deleteConfirmation.button"
                        )}
                      />
                    );
                  }
                })
              ) : isLoading ? (
                viewMode === "list" ? (
                  <LoadingListCards />
                ) : (
                  <LoadingGridCards />
                )
              ) : (
                <p className="body-text">{t("sites.list.empty")}</p>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </RightMainContainer>
    </>
  );
}
