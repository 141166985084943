import { Children, useState, useDeferredValue, useEffect } from "react";
import { AddIcon } from "allIcons";
import {
  AddFromTemplateLibrary,
  BodyContainer,
  FiltersList,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PopOver,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
} from "commonUI";
import { contentRequestItemType, search } from "helpers";
import {
  useAddDefaultContentRequestItem,
  useContentRequestsItem,
  useDefaultContentRequestItem,
  useDeleteDefaultContentRequestItem,
} from "api/templateLibrary/contentRequestsItem";
import { useTags } from "api/siteSettings/Tags";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function ContentRequestItems() {
  const { data: contentRequestItems, isLoading: loadingContentRequestItems } =
    useContentRequestsItem();
  const {
    data: defaultContentRequestItems,
    isLoading: loadingDefaultContentRequestItems,
  } = useDefaultContentRequestItem();
  const { mutate: addDefaultContentRequestItem, isLoading: isAdding } =
    useAddDefaultContentRequestItem();
  const { mutate: deleteDefaultContentRequestItem, isLoading: isDeleting } =
    useDeleteDefaultContentRequestItem();
  const [addContentRequestItem, setAddContentRequestItem] = useState(false);

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { remove_items_from_site_template } = useUserPermissions();

  useEffect(() => {
    setSearchResult(
      defaultContentRequestItems.filter((item) =>
        search(item.title, defferedSearch)
      )
    );
  }, [defferedSearch, defaultContentRequestItems]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        defaultContentRequestItems
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {isAdding || isDeleting ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <PrimaryHeading>Content Request Items</PrimaryHeading>
          <div className="relative">
            <MainButton onClick={() => setAddContentRequestItem(true)}>
              <AddIcon />
              Add
            </MainButton>
            <PopOver
              open={addContentRequestItem}
              onClose={() => setAddContentRequestItem(false)}
              style={{ right: "0", width: "300px" }}
              toolTip={{
                right: "35px",
                vertical: "top",
              }}
            >
              <AddFromTemplateLibrary
                data={contentRequestItems
                  .filter((item) => item.default_tem !== "1")
                  .map((item) => ({
                    ...item,
                    icon: contentRequestItemType[item.hint || item.type],
                    onClick: () => addDefaultContentRequestItem(item.id),
                  }))}
                emptyTemplate={{
                  label: "Content Request Item",
                  linkLabel: "Content Request Item",
                  link: "/template-library/content-request-items/add",
                }}
                isLoading={loadingContentRequestItems}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingDefaultContentRequestItems ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Content Request Item"
              linkLabel="Content Request Item"
              onClick={() => setAddContentRequestItem(true)}
            />
          ) : (
            Children.toArray(
              searchResult.map((item, index) => {
                return (
                  <ListCard
                    editIcon={false}
                    data={item.title}
                    icon={
                      <ListIconWrapper>
                        {contentRequestItemType[item.hint || item.type]}
                      </ListIconWrapper>
                    }
                    onCross={() => deleteDefaultContentRequestItem(item.id)}
                    deleteIcon
                    crossIcon={remove_items_from_site_template}
                    showModalonCross
                    deleteTitle="Remove This Template?"
                    deleteButtonText="Confirm"
                    deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
