import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getLaunchpadShortcuts,
  updateLaunchpadShortcuts,
} from "api/siteSettings/launchpadShortcuts";
import { ApiResponseType, NotificationType } from "enums";
import { useUtilities, useSearch } from "helpers";
import { t } from "i18next";
import { useEffect } from "react";
import { setLaunchpadShortcutsAction } from "redux/reducers/siteSettings/launchpadShortcuts";

function useGetLaunchpadShortcuts() {
  const { web = null } = useSearch();
  return useQuery(["launchpadShortcuts", web], () =>
    getLaunchpadShortcuts(web)
  );
}

export function useLaunchpadShortcuts() {
  const { data, isLoading, error } = useGetLaunchpadShortcuts();
  const {
    launchpadShortcuts: { launchpadShortcuts, website_id, loaded },
    dispatch,
  } = useUtilities();
  const { web = null } = useSearch();

  useEffect(() => {
    if (data && (!loaded || website_id !== web)) {
      dispatch(
        setLaunchpadShortcutsAction({
          launchpadShortcuts: data.Data[0] || {
            launchpad_settings:
              '{"users": true, "phases": true, "activity": true, "assignments": true, "project_brief": true, "startup_tools": true, "onboarding_checklist": true}',
          },
          website_id: web,
        })
      );
    }
  }, [data, loaded, web]);

  return {
    data: launchpadShortcuts,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
  };
}

export function useUpdateLaunchpadShortcuts() {
  const { dispatch, notification } = useUtilities();
  const { web = null } = useSearch();
  return useMutation(updateLaunchpadShortcuts, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(
          setLaunchpadShortcutsAction({
            launchpadShortcuts: data.Data,
            website_id: web,
          })
        );
        notification({
          type: NotificationType.SUCCESS,
          message: t("siteSetting.launchpadWidgets.api.notification.updated"),
        });
      }
    },
    onError: (error) => {},
  });
}
