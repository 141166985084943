import { Link } from "react-router-dom";
import { useDeferredValue, useEffect, useState } from "react";
import {
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  MainButton,
  BodyContainer,
  ListIconWrapper,
  ListCard,
  LoadingListCards,
  TemplateNotFound,
  InputField,
  FiltersList,
  UserAvatar,
} from "commonUI";
import { AddIcon, CompaniesIcon } from "allIcons";
import { useCompanies } from "api/usersModule/companies";
import { parse, search } from "helpers";
import { useMembers } from "api/usersModule/members";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { t } from "i18next";

export default function Companies() {
  const { data: members } = useMembers();
  const { data: companies, isLoading, error } = useCompanies();
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const defferedSearch = useDeferredValue(searchText);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchedData(
      companies.filter((_) => search(_.company_name, defferedSearch))
    );
  }, [defferedSearch, companies]);

  const applySorting = (oder, orderBy) => {
    setSearchedData(
      libraryItemSorting(oder, orderBy, searchedData, "company_name")
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchedData(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        companies.map((item) => ({
          ...item,
          users: parse(item.users).map((user) => user.id),
        })),
        "users"
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>{t("users.companies.title")}</PrimaryHeading>
          <Link to="/users/companies/add">
            <MainButton>
              <AddIcon />
              {t("button.add")}
            </MainButton>
          </Link>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon
            filter
            value={searchText}
            setValue={(value) => setSearchText(value)}
            showTags={false}
            filterAddTitle={t("users.companies.filter.addUser")}
            filterTitle={t("users.companies.filter.addUser.title")}
            filterData={filterData}
            filterList={members?.map((item) => ({
              title: `${item.name} ${item.last_name}`,
              id: item.id,
              users: item.id,
              icon: (
                <ListIconWrapper>{<UserAvatar user={item} />}</ListIconWrapper>
              ),
            }))}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : searchedData.length === 0 ? (
            <TemplateNotFound
              label={t("users.module.company")}
              linkLabel={t("users.module.company")}
              link="/users/companies/add"
            />
          ) : (
            searchedData.map((item, index) => {
              return (
                <ListCard
                  data={item.company_name}
                  link={"/users/companies/" + item.id}
                  strictLink
                  deleteIcon={true}
                  icon={
                    <ListIconWrapper className="">
                      <CompaniesIcon fill="white" />
                    </ListIconWrapper>
                  }
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
