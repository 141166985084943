import { useEffect, useState } from "react";
import {
  ToggleCard,
  RightMainContainer,
  HeaderContainer,
  BodyContainer,
  InputField,
  PrimaryHeading,
  SecondaryHeading,
  MainButton,
  Loader,
  Divider,
} from "commonUI";
import { SaveIcon } from "allIcons";
import { isEqual, parse, useSearch } from "helpers";
import { useApprovals, useUpdateApprovalSetting } from "hooks/approvals";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export function ApprovalCard({
  inputValue,
  handleInputChange,
  checkbox,
  checkboxChange,
  signature,
  signatureChange,
  isLoading,
  singularTermOfTitle,
  className,
  title,
}) {
  return (
    <>
      <div className={`${className ?? ""}`}>
        <PrimaryHeading className="text-17">{title}</PrimaryHeading>
        <SecondaryHeading className="mb-1-5 mt-3">
          {t("siteSetting.approvals.requireCheckbox.title", {
            module: title,
          })}
        </SecondaryHeading>
        <p className="body-text text-theme-secondary mt-0">
          {t("siteSetting.approvals.requireCheckbox.description", {
            module: title,
          })}
        </p>
        <div className="">
          <ToggleCard
            value={checkbox}
            setValue={checkboxChange}
            isLoading={isLoading}
          />
        </div>
        <InputField
          value={inputValue}
          setValue={handleInputChange}
          label={t(
            "siteSetting.approvals.requireConfirmationText.title",
            { module: singularTermOfTitle }
          )}
          placeholder={t("siteSetting.approvals.requireConfirmationText.placeholder", {module: singularTermOfTitle})}
          className="!gap-2 mt-4-5 mb-3"
        />
        <SecondaryHeading className="mb-1-5 mt-2">
          {t("siteSetting.approvals.requireDigitalSignature.title", {module: title})}
        </SecondaryHeading>
        <p className="body-text text-theme-secondary mt-0">
        {t("siteSetting.approvals.requireDigitalSignature.description", {module: title})}
        </p>
        <div className="">
          <ToggleCard
            value={signature}
            setValue={signatureChange}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default function ApprovalSettings() {
  const { web = null } = useSearch();
  const { data: approvals, isLoading } = useApprovals();
  const { mutate: updateApproval, isLoading: updatingApproval } =
    useUpdateApprovalSetting();
  const [inputValue, setInputValue] = useState({
    site_approval_checkbox: true,
    site_approval_text:
      "I confirm I have reviewed the design, content, and functionality of the entire site and everything meets my approval.",
    site_approval_digital_sign: true,

    page_approval_checkbox: true,
    page_approval_text:
      "I confirm I have reviewed the design, content, and functionality of this page and everything meets my approval.",
    page_approval_digital_sign: true,

    comment_approval_checkbox: false,
    comment_approval_text:
      "I confirm I have reviewed the design, content, and functionality of this comment and everything meets my approval.",
    comment_approval_digital_sign: false,
  });
  const { edit_item_details_in_site_template } = useUserPermissions();
  const [loading, setLoading] = useState(true);

  const handleInputChange = (key, value) => {
    setInputValue((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    if (isLoading) return;
    const approval = approvals.find((_) => isEqual(_.website_id, web));
    if (approval) {
      setInputValue(parse(approval.setting_options) ?? inputValue);
    }
  }, [web, approvals, isLoading]);

  const handleSaveChanges = () => {
    const approval = approvals.find((_) => isEqual(_.website_id, web));
    updateApproval({
      ...(approval ?? {}),
      website_id: web,
      setting_options: inputValue,
    });
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={updatingApproval} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>{t("siteSetting.approvals.title")}</PrimaryHeading>
          {edit_item_details_in_site_template ? (
            <MainButton onClick={handleSaveChanges}>
              <SaveIcon />{t("button.saveChanges")}
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <ApprovalCard
            title={t("siteSetting.approvals.site")}
            singularTermOfTitle={t("siteSetting.approvals.site")}
            checkbox={inputValue.site_approval_checkbox}
            checkboxChange={(value) =>
              handleInputChange("site_approval_checkbox", value)
            }
            signature={inputValue.site_approval_digital_sign}
            signatureChange={(value) =>
              handleInputChange("site_approval_digital_sign", value)
            }
            inputValue={inputValue.site_approval_text}
            handleInputChange={(value) =>
              handleInputChange("site_approval_text", value)
            }
            isLoading={isLoading || loading}
          />
          <Divider height={2} className="mt-4" />
          <ApprovalCard
            className="mt-4"
            title={t("siteSetting.approvals.pages")}
            singularTermOfTitle={t("siteSetting.approvals.page")}
            checkbox={inputValue.page_approval_checkbox}
            checkboxChange={(value) =>
              handleInputChange("page_approval_checkbox", value)
            }
            signature={inputValue.page_approval_digital_sign}
            signatureChange={(value) =>
              handleInputChange("page_approval_digital_sign", value)
            }
            inputValue={inputValue.page_approval_text}
            handleInputChange={(value) =>
              handleInputChange("page_approval_text", value)
            }
          />
          <Divider height={2} className="mt-4" />
          <ApprovalCard
            className="mt-4"
            title={t("siteSetting.approvals.comments")}
            singularTermOfTitle={t("siteSetting.approvals.comment")}
            checkbox={inputValue.comment_approval_checkbox}
            checkboxChange={(value) =>
              handleInputChange("comment_approval_checkbox", value)
            }
            signature={inputValue.comment_approval_digital_sign}
            signatureChange={(value) =>
              handleInputChange("comment_approval_digital_sign", value)
            }
            inputValue={inputValue.comment_approval_text}
            handleInputChange={(value) =>
              handleInputChange("comment_approval_text", value)
            }
          />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
