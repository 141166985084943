import { Link } from "react-router-dom";
import { SecondaryHeading } from "commonUI";
import { t } from "i18next";

export default function TemplateNotFound({
  link,
  label,
  linkLabel,
  onClick,
  filter = true,
}) {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="template-notfound-containter">
        <SecondaryHeading className="mt-1">
          {
            t("module.noDataToShow.title", {
              module: label ?? t("module.noDataToShow.defaultModule")
            })
          }
        </SecondaryHeading>
        <p className="text-theme-placeholder text-theme-blue text-13 font-semibold m-0 pt-2 text-center">
          {filter ? t("module.noDataToShow.details.adjustFilter") : link || onClick ? t("module.noDataToShow.details.tryTo"): ''}&nbsp;
          {filter && (link || onClick) ? t("module.noDataToShow.details.or") : ""} &nbsp;
          {link || onClick ? (
            <Link
              onClick={(e) => {
                if (onClick) {
                  e.preventDefault();
                  onClick();
                }
              }}
              to={link ?? "/"}
              className="text-theme-blue text-13 font-semibold underline"
            >
              {
                t("module.noDataToShow.addNew", {
                  module: linkLabel ?? t("module.noDataToShow.defaultModule")
                })
              }
            </Link>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
}
