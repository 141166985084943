import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SelectField,
  IconWrapper,
  SecondaryHeading,
  InputField,
  BodyContainer,
  MainButton,
  PrimaryHeading,
  ToggleCard,
  RightMainContainer,
  HeaderContainer,
  CheckBox,
  Divider,
  Loader,
  DeleteButton,
} from "commonUI";
import { BackArrow, SaveIcon } from "allIcons";
import { isEqual, isOwner, objectValues, parse, useUtilities } from "helpers";
import {
  useAddRole,
  useCheckRoleBeforeDelete,
  useDeleteRole,
  useRoles,
  useUpdateRole,
} from "api/usersModule/roles";
import { USERTYPE } from "usersModule/enums";
import { t } from "i18next";

export const PermissionCard = ({ value, setValue, heading, last }) => {
  return (
    <>
      <div className={`permission-card`}>
        <SecondaryHeading className="text-13 font-semibold text-theme-secondary">
          {heading}
        </SecondaryHeading>
        <ToggleCard value={value} setValue={setValue} />
      </div>
    </>
  );
};

export default function AddEditRole() {
  const { id } = useParams();
  const { notification, navigate } = useUtilities();
  const [roleCanBeDeleted, setRoleCanBeDeleted] = useState(true);
  const [role, setRole] = useState({
    role_title: "",
    user_role: [...new Set(Object.values(USERTYPE))][0],
    system_status: 0,
    app_permissions: {
      app_general_log_into_app: true,
      app_users_view_users: true,
      app_users_edit_users: true,
      app_users_add_new_users: true,
      app_users_delete_users: true,
      view_templates: true,
      add_new_templates: true,
      edit_templates: true,
      delete_templates: true,
      view_sites: true,
      add_new_site: true,
      delete_site: true,
      view_site_template: true,
      add_new_items_to_site_template: true,
      add_items_from_template_library: true,
      edit_item_details_in_site_template: true,
      remove_items_from_site_template: true,
    },
    plugin_permissions: {
      view_site_settings: true,
      add_new_items_to_site_settings: true,
      add_items_from_template_library: true,
      edit_item_details_in_site_settings: true,
      save_items_as_templates: true,
      remove_items_from_site_settings: true,

      view_comments: true,
      add_new_comments: true,
      reply_to_discussion_in_comments: true,
      edit_comment_details: true,
      delete_comments_added_by_user: true,
      delete_comments_added_by_other_users: true,

      view_pages: true,
      add_current_page: true,
      reply_to_discussion_in_pages: true,
      edit_page_details: true,
      delete_pages: true,

      view_tasks: true,
      add_new_tasks: true,
      add_task_templates_from_template_library: true,
      reply_to_discussion_in_tasks: true,
      edit_task_details: true,
      save_tasks_as_templates: true,
      delete_tasks_added_by_user: true,
      delete_tasks_added_by_other_users: true,

      view_checklists: true,
      add_new_checklists: true,
      add_checklist_templates_from_template_library: true,
      reply_to_discussion_in_checklists: true,
      edit_checklist_details: true,
      save_checklists_as_templates: true,
      delete_checklist_added_by_user: true,
      delete_checklist_added_by_other_users: true,

      view_files: true,
      add_new_files: true,
      reply_to_discussion_in_files: true,
      edit_file_details: true,
      delete_files_added_by_user: true,
      delete_files_added_by_other_users: true,

      view_content_requests: true,
      add_new_content_requests: true,
      add_content_request_templates_from_template_library: true,
      reply_to_discussion_in_content_requests: true,
      edit_content_request_details: true,
      save_content_requests_as_templates: true,
      delete_content_requests_added_by_user: true,
      delete_content_requests_added_by_other_users: true,

      view_feedback_requests: true,
      add_new_feedback_requests: true,
      reply_to_discussion_in_feedback_requests: true,
      edit_feedback_request_details: true,
      delete_feedback_requests_added_by_user: true,
      delete_feedback_requests_added_by_other_users: true,

      view_approval_requests: true,
      add_new_approval_requests: true,
      reply_to_discussion_in_approval_requests: true,
      edit_approval_requests_details: true,
      delete_approval_requests_added_by_user: true,
      delete_approval_requests_added_by_other_users: true,

      view_change_requests: true,
      add_new_change_requests: true,
      reply_to_discussion_in_change_requests: true,
      edit_change_requests_details: true,
      delete_change_requests_added_by_user: true,
      delete_change_requests_added_by_other_users: true,

      view_view_notes: true,
      add_new_view_notes: true,
      add_view_note_templates_from_template_library: true,
      reply_to_discussion_in_view_notes: true,
      edit_view_notes_details: true,
      save_view_notes_as_templates: true,
      delete_notes_added_by_user: true,
      delete_notes_added_by_other_users: true,

      view_view_tutorials: true,
      add_new_view_tutorials: true,
      add_view_tutorials_templates_from_template_library: true,
      reply_to_discussion_in_view_tutorials: true,
      edit_view_tutorials_details: true,
      save_view_tutorials_as_templates: true,
      delete_tutorials_added_by_user: true,
      delete_tutorials_added_by_other_users: true,

      view_support_requests: true,
      add_new_support_requests: true,
      reply_to_discussion_in_support_requests: true,
      edit_support_requests_details: true,
      delete_support_requests_added_by_user: true,
      delete_support_requests_added_by_other_users: true,
    },
  });
  const { data: roles, isLoading: loadingRoles } = useRoles();
  const { mutate: addRole, isLoading: addingRole } =
    useAddRole("/users/roles/");
  const { mutate: updateRole, isLoading: updatingRole } = useUpdateRole();
  const { mutate: deleteRole, isLoading: deletingRole } =
    useDeleteRole("/users/roles");
  const {
    mutateAsync: checkRoleBeforeDelete,
    isLoading: checkingRoleBeforeDelete,
  } = useCheckRoleBeforeDelete();
  useEffect(() => {
    if (!loadingRoles && roles) {
      const tempRole = roles.find((item) => isEqual(item.id, id));
      if (tempRole)
        setRole({
          ...tempRole,
          app_permissions: parse(tempRole.app_permissions),
          plugin_permissions: parse(tempRole.plugin_permissions),
        });
    }
  }, [loadingRoles, roles, id]);

  function setAppPermission(key, value) {
    setRole({
      ...role,
      app_permissions: {
        ...role.app_permissions,
        [key]: value,
      },
    });
  }
  function setPluginPermission(key, value) {
    setRole({
      ...role,
      plugin_permissions: {
        ...role.plugin_permissions,
        [key]: value,
      },
    });
  }

  function saveRole(e) {
    e.preventDefault();
    if (!role.role_title) {
      notification({
        type: "error",
        message: t("users.roles.error.addRoleTitle"),
      });
      return;
    }
    if (isEqual(id, "add")) {
      addRole({ data: role });
    } else {
      const { id: role_id, ...rest } = role;
      updateRole({ ...rest, role_id });
    }
  }

  return (
    <>
      <RightMainContainer>
        <Loader
          loader="block"
          show={
            addingRole ||
            updatingRole ||
            deletingRole ||
            checkingRoleBeforeDelete
          }
        />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/users/roles")} />
            <PrimaryHeading>
              {isEqual(id, "add")
                ? t("users.roles.error.addRoleTitle")
                : role.role_title}
            </PrimaryHeading>
          </IconWrapper>
          {!isOwner(role) ? (
            <MainButton onClick={saveRole}>
              <SaveIcon />
              {t("button.saveChanges")}
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label={t("users.roles.input.roleTitle.label")}
            value={role.role_title}
            setValue={(value) => setRole({ ...role, role_title: value })}
            placeholder={t("users.roles.input.roleTitle.placeholder")}
            disabled={isOwner(role)}
          />
          <SelectField
            label={t("users.roles.input.userType.label")}
            placeholder={t("users.roles.input.userType.placeholder")}
            value={role.user_role}
            setValue={(value) => setRole({ ...role, user_role: value })}
            disabled={isOwner(role)}
          >
            {objectValues(USERTYPE).map((role) => (
              <option value={role}>{role}</option>
            ))}
          </SelectField>
          <PrimaryHeading className="text-17 mt-3">
            {t("users.roles.input.section.appPermission")}
          </PrimaryHeading>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.app.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.app.logIntoApp")}
                value={role.app_permissions.app_general_log_into_app}
                setValue={(value) =>
                  setAppPermission("app_general_log_into_app", value)
                }
                disabled={isOwner(role)}
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.users.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.users.viewUsers")}
                value={role.app_permissions.app_users_view_users}
                setValue={(value) =>
                  setAppPermission("app_users_view_users", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.users.editUsers")}
                value={role.app_permissions.app_users_edit_users}
                setValue={(value) =>
                  setAppPermission("app_users_edit_users", value)
                }
                disabled={
                  isEqual(role.app_permissions.app_users_view_users, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.users.addNewUsers")}
                value={role.app_permissions.app_users_add_new_users}
                setValue={(value) =>
                  setAppPermission("app_users_add_new_users", value)
                }
                disabled={
                  isEqual(role.app_permissions.app_users_view_users, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.users.deleteUsers")}
                value={role.app_permissions.app_users_delete_users}
                setValue={(value) =>
                  setAppPermission("app_users_delete_users", value)
                }
                disabled={
                  isEqual(role.app_permissions.app_users_view_users, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.templateLibrary.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.templateLibrary.viewTemplates"
                )}
                value={role.app_permissions.view_templates}
                setValue={(value) => setAppPermission("view_templates", value)}
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.templateLibrary.addTemplates"
                )}
                value={role.app_permissions.add_new_templates}
                setValue={(value) =>
                  setAppPermission("add_new_templates", value)
                }
                disabled={
                  isEqual(role.app_permissions.view_templates, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.templateLibrary.editTemplates"
                )}
                value={role.app_permissions.edit_templates}
                setValue={(value) => setAppPermission("edit_templates", value)}
                disabled={
                  isEqual(role.app_permissions.view_templates, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.templateLibrary.deleteTemplates"
                )}
                value={role.app_permissions.delete_templates}
                setValue={(value) =>
                  setAppPermission("delete_templates", value)
                }
                disabled={
                  isEqual(role.app_permissions.view_templates, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.sites.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.sites.viewSites")}
                value={role.app_permissions.view_sites}
                setValue={(value) => setAppPermission("view_sites", value)}
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.sites.addSites")}
                value={role.app_permissions.add_new_site}
                setValue={(value) => setAppPermission("add_new_site", value)}
                disabled={
                  isEqual(role.app_permissions.view_sites, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.sites.deleteSites")}
                value={role.app_permissions.delete_site}
                setValue={(value) => setAppPermission("delete_site", value)}
                disabled={
                  isEqual(role.app_permissions.view_sites, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.defaultSiteTemplate.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.defaultSiteTemplate.viewSiteTemplate"
                )}
                value={role.app_permissions.view_site_template}
                setValue={(value) =>
                  setAppPermission("view_site_template", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.defaultSiteTemplate.addNewItemsToSiteTemplate"
                )}
                value={role.app_permissions.add_new_items_to_site_template}
                setValue={(value) =>
                  setAppPermission("add_new_items_to_site_template", value)
                }
                disabled={
                  isEqual(role.app_permissions.view_site_template, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.defaultSiteTemplate.addItemsFromTemplateLibrary"
                )}
                value={role.app_permissions.add_items_from_template_library}
                setValue={(value) =>
                  setAppPermission("add_items_from_template_library", value)
                }
                disabled={
                  isEqual(role.app_permissions.view_site_template, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.defaultSiteTemplate.editItemsInSiteTemplate"
                )}
                value={role.app_permissions.edit_item_details_in_site_template}
                setValue={(value) =>
                  setAppPermission("edit_item_details_in_site_template", value)
                }
                disabled={
                  isEqual(role.app_permissions.view_site_template, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.defaultSiteTemplate.removeItemsFromSiteTemplate"
                )}
                value={role.app_permissions.remove_items_from_site_template}
                setValue={(value) =>
                  setAppPermission("remove_items_from_site_template", value)
                }
                disabled={
                  isEqual(role.app_permissions.view_site_template, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <Divider className="mt-5 mb-3" height={2} />
          <PrimaryHeading className="text-17 mt-2">
            {t("users.roles.input.section.pluginPermission")}
          </PrimaryHeading>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.siteSetting.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.siteSetting.viewSiteSettings"
                )}
                value={role.plugin_permissions.view_site_settings}
                setValue={(value) =>
                  setPluginPermission("view_site_settings", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.siteSetting.addNewItemsToSiteSettings"
                )}
                value={role.plugin_permissions.add_new_items_to_site_settings}
                setValue={(value) =>
                  setPluginPermission("add_new_items_to_site_settings", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_site_settings, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.siteSetting.addNewItemsFromTemplateLibrary"
                )}
                value={role.plugin_permissions.add_items_from_template_library}
                setValue={(value) =>
                  setPluginPermission("add_items_from_template_library", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_site_settings, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.siteSetting.editItemDetailsInSiteSettings"
                )}
                value={
                  role.plugin_permissions.edit_item_details_in_site_settings
                }
                setValue={(value) =>
                  setPluginPermission(
                    "edit_item_details_in_site_settings",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_site_settings, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.siteSetting.saveItemsAsTemplate"
                )}
                value={role.plugin_permissions.save_items_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_items_as_templates", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_site_settings, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.siteSetting.removeItemsFromSiteSettings"
                )}
                value={role.plugin_permissions.remove_items_from_site_settings}
                setValue={(value) =>
                  setPluginPermission("remove_items_from_site_settings", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_site_settings, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.comments.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.comments.viewComments")}
                value={role.plugin_permissions.view_comments}
                setValue={(value) =>
                  setPluginPermission("view_comments", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.comments.addComments")}
                value={role.plugin_permissions.add_new_comments}
                setValue={(value) =>
                  setPluginPermission("add_new_comments", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_comments, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.comments.replyToDiscussionInComments"
                )}
                value={role.plugin_permissions.reply_to_discussion_in_comments}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_comments", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_comments, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.comments.editComments")}
                value={role.plugin_permissions.edit_comment_details}
                setValue={(value) =>
                  setPluginPermission("edit_comment_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_comments, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.comments.deleteCommentsAddedByUser"
                )}
                value={role.plugin_permissions.delete_comments_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_comments_added_by_user", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_comments, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.comments.deleteCommentsAddedByOtherUsers"
                )}
                value={
                  role.plugin_permissions.delete_comments_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_comments_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_comments, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.pages.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.pages.viewPages")}
                value={role.plugin_permissions.view_pages}
                setValue={(value) => setPluginPermission("view_pages", value)}
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.pages.addCurrentPages")}
                value={role.plugin_permissions.add_current_page}
                setValue={(value) =>
                  setPluginPermission("add_current_page", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_pages, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.pages.replyToDiscussionInPages"
                )}
                value={role.plugin_permissions.reply_to_discussion_in_pages}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_pages", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_pages, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.pages.editPageDetails")}
                value={role.plugin_permissions.edit_page_details}
                setValue={(value) =>
                  setPluginPermission("edit_page_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_pages, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.pages.deletePages")}
                value={role.plugin_permissions.delete_pages}
                setValue={(value) => setPluginPermission("delete_pages", value)}
                disabled={
                  isEqual(role.plugin_permissions.view_pages, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.tasks.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.tasks.viewTasks")}
                value={role.plugin_permissions.view_tasks}
                setValue={(value) => setPluginPermission("view_tasks", value)}
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.tasks.addNewTasks")}
                value={role.plugin_permissions.add_new_tasks}
                setValue={(value) =>
                  setPluginPermission("add_new_tasks", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tasks.addTaskTemplateFromTemplateLibrary"
                )}
                value={
                  role.plugin_permissions
                    .add_task_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_task_templates_from_template_library",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tasks.replyToDiscussionInTasks"
                )}
                value={role.plugin_permissions.reply_to_discussion_in_tasks}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_tasks", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.tasks.editTaskDetails")}
                value={role.plugin_permissions.edit_task_details}
                setValue={(value) =>
                  setPluginPermission("edit_task_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.tasks.saveTasksAsTemplate")}
                value={role.plugin_permissions.save_tasks_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_tasks_as_templates", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tasks.deleteTasksAddedByUser"
                )}
                value={role.plugin_permissions.delete_tasks_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_tasks_added_by_user", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tasks.deleteTasksAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions.delete_tasks_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_tasks_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_tasks, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.checklists.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.checklists.viewChecklists")}
                value={role.plugin_permissions.view_checklists}
                setValue={(value) =>
                  setPluginPermission("view_checklists", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.addNewChecklists"
                )}
                value={role.plugin_permissions.add_new_checklists}
                setValue={(value) =>
                  setPluginPermission("add_new_checklists", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.addChecklistTemplateFromTemplateLibrary"
                )}
                value={
                  role.plugin_permissions
                    .add_checklist_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_checklist_templates_from_template_library",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.replyToDiscussionInChecklists"
                )}
                value={
                  role.plugin_permissions.reply_to_discussion_in_checklists
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_checklists",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.editChecklistDetails"
                )}
                value={role.plugin_permissions.edit_checklist_details}
                setValue={(value) =>
                  setPluginPermission("edit_checklist_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.saveChecklistsAsTemplates"
                )}
                value={role.plugin_permissions.save_checklists_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_checklists_as_templates", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.deleteChecklistsAddedByUser"
                )}
                value={role.plugin_permissions.delete_checklist_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_checklist_added_by_user", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.checklists.deleteChecklistsAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions.delete_checklist_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_checklist_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_checklists, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.files.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.files.viewFiles")}
                value={role.plugin_permissions.view_files}
                setValue={(value) => setPluginPermission("view_files", value)}
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.files.addNewFiles")}
                value={role.plugin_permissions.add_new_files}
                setValue={(value) =>
                  setPluginPermission("add_new_files", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_files, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.files.replyToDiscussionInFiles"
                )}
                value={role.plugin_permissions.reply_to_discussion_in_files}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_files", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_files, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.files.editFileDetails")}
                value={role.plugin_permissions.edit_file_details}
                setValue={(value) =>
                  setPluginPermission("edit_file_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_files, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.files.deleteFilesAddedByUser"
                )}
                value={role.plugin_permissions.delete_files_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_files_added_by_user", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_files, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.files.deleteFilesAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions.delete_files_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_files_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_files, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.contentRequests.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.viewContentRequest"
                )}
                value={role.plugin_permissions.view_content_requests}
                setValue={(value) =>
                  setPluginPermission("view_content_requests", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.addNewContentRequest"
                )}
                value={role.plugin_permissions.add_new_content_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_content_requests", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.addContentRequestTemplatesFromTemplateLibrary"
                )}
                value={
                  role.plugin_permissions
                    .add_content_request_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_content_request_templates_from_template_library",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.replyToDiscussionInContentRequests"
                )}
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_content_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_content_requests",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.editContentRequestsDetails"
                )}
                value={role.plugin_permissions.edit_content_request_details}
                setValue={(value) =>
                  setPluginPermission("edit_content_request_details", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              />
              {/* <CheckBox
                label="Save Content Requests As Templates"
                value={
                  role.plugin_permissions.save_content_requests_as_templates
                }
                setValue={(value) =>
                  setPluginPermission(
                    "save_content_requests_as_templates",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              /> */}
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.deleteContentRequestsAddedByUser"
                )}
                value={
                  role.plugin_permissions.delete_content_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_content_requests_added_by_user",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.contentRequests.deleteContentRequestsAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions
                    .delete_content_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_content_requests_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_content_requests,
                    false
                  ) || isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.feedbackRequests.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.feedbackRequests.viewFeedbackRequests"
                )}
                value={role.plugin_permissions.view_feedback_requests}
                setValue={(value) =>
                  setPluginPermission("view_feedback_requests", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.feedbackRequests.addNewFeedbackRequests"
                )}
                value={role.plugin_permissions.add_new_feedback_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_feedback_requests", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_feedback_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.feedbackRequests.replyToDiscussionInFeedbackRequests"
                )}
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_feedback_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_feedback_requests",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_feedback_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.feedbackRequests.editFeedbackRequestDetails"
                )}
                value={role.plugin_permissions.edit_feedback_request_details}
                setValue={(value) =>
                  setPluginPermission("edit_feedback_request_details", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_feedback_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.feedbackRequests.deleteFeedbackRequestAddedByUser"
                )}
                value={
                  role.plugin_permissions.delete_feedback_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_feedback_requests_added_by_user",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_feedback_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.feedbackRequests.deleteFeedbackRequestAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions
                    .delete_feedback_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_feedback_requests_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_feedback_requests,
                    false
                  ) || isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.approvalRequests.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.approvalRequests.viewApprovalRequests"
                )}
                value={role.plugin_permissions.view_approval_requests}
                setValue={(value) =>
                  setPluginPermission("view_approval_requests", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.approvalRequests.addNewApprovalRequests"
                )}
                value={role.plugin_permissions.add_new_approval_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_approval_requests", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_approval_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.approvalRequests.replyToDiscussionInApprovalRequests"
                )}
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_approval_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_approval_requests",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_approval_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.approvalRequests.editApprovalRequestDetails"
                )}
                value={role.plugin_permissions.edit_approval_requests_details}
                setValue={(value) =>
                  setPluginPermission("edit_approval_requests_details", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_approval_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.approvalRequests.deleteApprovalRequestAddedByUser"
                )}
                value={
                  role.plugin_permissions.delete_approval_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_approval_requests_added_by_user",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_approval_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.approvalRequests.deleteApprovalRequestAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions
                    .delete_approval_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_approval_requests_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_approval_requests,
                    false
                  ) || isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.changeRequests.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.changeRequests.viewChangeRequests"
                )}
                value={role.plugin_permissions.view_change_requests}
                setValue={(value) =>
                  setPluginPermission("view_change_requests", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.changeRequests.addNewChangeRequests"
                )}
                value={role.plugin_permissions.add_new_change_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_change_requests", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_change_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.changeRequests.replyToDiscussionInChangeRequests"
                )}
                value={
                  role.plugin_permissions.reply_to_discussion_in_change_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_change_requests",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_change_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.changeRequests.editChangeRequestsDetails"
                )}
                value={role.plugin_permissions.edit_change_requests_details}
                setValue={(value) =>
                  setPluginPermission("edit_change_requests_details", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_change_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.changeRequests.deleteChangeRequestsAddedByUser"
                )}
                value={
                  role.plugin_permissions.delete_change_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_change_requests_added_by_user",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_change_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.changeRequests.deleteChangeRequestsAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions
                    .delete_change_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_change_requests_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_change_requests,
                    false
                  ) || isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.notes.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.notes.viewNotes")}
                value={role.plugin_permissions.view_view_notes}
                setValue={(value) =>
                  setPluginPermission("view_view_notes", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.notes.addNewNotes")}
                value={role.plugin_permissions.add_new_view_notes}
                setValue={(value) =>
                  setPluginPermission("add_new_view_notes", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.notes.addNoteTemplatesFromTemplateLibrary"
                )}
                value={
                  role.plugin_permissions
                    .add_view_note_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_view_note_templates_from_template_library",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.notes.replyToDiscussionInNotes"
                )}
                value={
                  role.plugin_permissions.reply_to_discussion_in_view_notes
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_view_notes",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.notes.editNotesDetails")}
                value={role.plugin_permissions.edit_view_notes_details}
                setValue={(value) =>
                  setPluginPermission("edit_view_notes_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t("users.roles.input.module.notes.saveNotesAsTemplates")}
                value={role.plugin_permissions.save_view_notes_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_view_notes_as_templates", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.notes.deleteNotesAddedByUser"
                )}
                value={role.plugin_permissions.delete_notes_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_notes_added_by_user", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.notes.deleteNotesAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions.delete_notes_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_notes_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_notes, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.tutorials.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t("users.roles.input.module.tutorials.viewTutorials")}
                value={role.plugin_permissions.view_view_tutorials}
                setValue={(value) =>
                  setPluginPermission("view_view_tutorials", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t("users.roles.input.module.tutorials.addNewTutorials")}
                value={role.plugin_permissions.add_new_view_tutorials}
                setValue={(value) =>
                  setPluginPermission("add_new_view_tutorials", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tutorials.addTutorialTemplatesFromTemplateLibrary"
                )}
                value={
                  role.plugin_permissions
                    .add_view_tutorials_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_view_tutorials_templates_from_template_library",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tutorials.replyToDiscussionInTutorials"
                )}
                value={
                  role.plugin_permissions.reply_to_discussion_in_view_tutorials
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_view_tutorials",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tutorials.editTutorialsDetails"
                )}
                value={role.plugin_permissions.edit_view_tutorials_details}
                setValue={(value) =>
                  setPluginPermission("edit_view_tutorials_details", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tutorials.saveTutorialsAsTemplates"
                )}
                value={role.plugin_permissions.save_view_tutorials_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_view_tutorials_as_templates", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tutorials.deleteTutorialsAddedByUser"
                )}
                value={role.plugin_permissions.delete_tutorials_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_tutorials_added_by_user", value)
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.tutorials.deleteTutorialsAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions.delete_tutorials_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_tutorials_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(role.plugin_permissions.view_view_tutorials, false) ||
                  isOwner(role)
                }
              />
            </div>
          </div>
          <div className="permission-card-list mt-3">
            <PrimaryHeading className="text-14">
              {t("users.roles.input.module.supportRequests.title")}
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label={t(
                  "users.roles.input.module.supportRequests.viewSupportRequests"
                )}
                value={role.plugin_permissions.view_support_requests}
                setValue={(value) =>
                  setPluginPermission("view_support_requests", value)
                }
                disabled={isOwner(role)}
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.supportRequests.addNewSupportRequests"
                )}
                value={role.plugin_permissions.add_new_support_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_support_requests", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_support_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.supportRequests.replyToDiscussionInSupportRequests"
                )}
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_support_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_support_requests",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_support_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.supportRequests.editSupportRequestsDetails"
                )}
                value={role.plugin_permissions.edit_support_requests_details}
                setValue={(value) =>
                  setPluginPermission("edit_support_requests_details", value)
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_support_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.supportRequests.deleteSupportRequestsAddedByUser"
                )}
                value={
                  role.plugin_permissions.delete_support_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_support_requests_added_by_user",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_support_requests,
                    false
                  ) || isOwner(role)
                }
              />
              <CheckBox
                label={t(
                  "users.roles.input.module.supportRequests.deleteSupportRequestsAddedByOtherUser"
                )}
                value={
                  role.plugin_permissions
                    .delete_support_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_support_requests_added_by_other_users",
                    value
                  )
                }
                disabled={
                  isEqual(
                    role.plugin_permissions.view_support_requests,
                    false
                  ) || isOwner(role)
                }
              />
            </div>
          </div>

          {isEqual(id, "add") ||
          isEqual(role.system_status, 1) ||
          isOwner(role) ? (
            ""
          ) : (
            <DeleteButton
              type="button"
              deleteMessage={
                roleCanBeDeleted
                  ? t("users.roles.delete.confirmation.description")
                  : t("users.roles.delete.error.usersAttched.description")
              }
              deleteTitle={
                roleCanBeDeleted
                  ? t("users.roles.delete.confirmation.title")
                  : t("users.roles.delete.error.usersAttched.title")
              }
              deleteButtonText={
                roleCanBeDeleted
                  ? t("common.popup.button.confirmDelete")
                  : t("common.popup.button.okay")
              }
              className="mt-3"
              style={{ left: "0" }}
              position="top"
              title={t("users.roles.delete.section.title")}
              message={t("users.roles.delete.section.description")}
              buttonText={t("users.roles.delete.section.button")}
              isDeleteButton={roleCanBeDeleted}
              onClose={() => setRoleCanBeDeleted(true)}
              onDelete={async () => {
                if (!roleCanBeDeleted) {
                  setRoleCanBeDeleted(true);
                  return;
                }
                const result = await checkRoleBeforeDelete(id).then((res) => {
                  if (isEqual(res.forTemp, 0) && isEqual(res.forTemp, 0)) {
                    deleteRole(id);
                  } else {
                    setRoleCanBeDeleted(false);
                    return true;
                  }
                  return;
                });
                return result;
              }}
            />
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
