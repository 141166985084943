import {
  ListIconWrapper,
  BodyContainer,
  PrimaryHeading,
  HeaderContainer,
  ListCard,
  RightMainContainer,
} from "commonUI";
import { StatusIcon } from "allIcons";
import { t } from "i18next";
import { useMemo } from "react";

export default function StatusTab() {
  const statusModules = useMemo(() => ([
    t("siteSetting.status.tasks"),
    t("siteSetting.status.requests"),
    t("siteSetting.status.phases"),
    t("siteSetting.status.comments"),
  ]), [])
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>{t("siteSetting.status.title")}</PrimaryHeading>
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {statusModules?.map((iner, index) => {
              return (
                <ListCard
                  key={index}
                  index={index}
                  data={iner}
                  link={`/site-settings/status/${iner
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                  strictLink={true}
                  deleteIcon={true}
                  icon={
                    <ListIconWrapper>
                      <StatusIcon />
                    </ListIconWrapper>
                  }
                />
              );
            })}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
