import { useEffect, useState } from "react";
import {
  PreviewCard,
  MainButton,
  ColorPicker,
  RightMainContainer,
  BodyContainer,
  HeaderContainer,
  PrimaryHeading,
  InputField,
  Loader,
  SecondaryHeading,
  ToggleCard,
  SelectField,
  Divider,
} from "commonUI";
import { SaveIcon } from "allIcons";
import { useEnvironments, useUpdateEnvironment } from "hooks/environments";
import { useSearch, parse } from "helpers";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export const EnvironmentsComponent = ({
  color,
  inputLabel,
  title,
  onColorChange,
  onLabelChange,
}) => {
  return (
    <>
      <div className="flex flex-col">
        <PrimaryHeading className="text-17">{title}</PrimaryHeading>
        <div className="mt-2">
          <PreviewCard
            label={t("siteSetting.environments.preview.title", {
              environment: title,
            })}
            color={color}
            title={inputLabel}
            description={t("siteSetting.environments.preview.description")}
            textStyle={{ color: "white" }}
            buttonStyle={{ backgroundColor: "#1D2327" }}
          />
        </div>
        <div className="">
          <InputField
            label={t("siteSetting.environments.label.title", {
              environment: title,
            })}
            value={inputLabel}
            setValue={onLabelChange}
            description={t("siteSetting.environments.label.description", {
              environment: title,
            })}
            inputDivClass="mt-0"
          />
        </div>
        <div className="">
          <InputField
            label={t("siteSetting.environments.color.title", {
              environment: title,
            })}
            placeholder={t("siteSetting.environments.color.placeholder", {
              environment: title,
            })}
            value={color}
            setValue={onColorChange}
            description={t("siteSetting.environments.color.description", {
              environment: title,
            })}
            inputDivClass="mt-0 mb-0"
          />
          <div className="mt-4">
            <ColorPicker value={color} setValue={onColorChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default function Environments() {
  const { web } = useSearch();
  const { data, isLoading } = useEnvironments();
  const { mutate: updateEnvironment, isLoading: isUpdating } =
    useUpdateEnvironment();
  const [loadingData, setLoadingData] = useState(true);
  const { edit_item_details_in_site_template } = useUserPermissions();

  const [inputValues, setInputValues] = useState({
    production_label: "Production",
    production_color: "#00D263",

    staging_label: "Staging",
    staging_color: "#9958EB",

    development_label: "Development",
    development_color: "#F87904",

    show_environment_badge: true,
    override_the_default_environment: "Default Environment",
  });

  useEffect(() => {
    if (!data || isLoading) return;

    const environment = data?.find((item) => item.website_id === (web || null));
    if (!environment) return;
    setInputValues(
      parse(environment?.environments_settings || "") || inputValues
    );
    setLoadingData(false);
  }, [data, isLoading]);

  const handleChangeInput = (key, value) => {
    setInputValues((pre) => ({ ...pre, [key]: value }));
  };

  const handleSaveChanges = () => {
    updateEnvironment({
      website_id: web || null,
      data: {
        ...inputValues,
        show_environment_badge: inputValues?.show_environment_badge ?? false,
        override_the_default_environment:
          inputValues?.override_the_default_environment ??
          "Default Environment",
      },
    });
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={isUpdating} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>{t("siteSetting.environments.title")}</PrimaryHeading>
          {edit_item_details_in_site_template ? (
            <MainButton
              onClick={handleSaveChanges}
              disabled={isLoading || isUpdating}
            >
              <SaveIcon />{t("button.saveChanges")}
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <SecondaryHeading>
            {t("siteSetting.environments.showEnvironmentBadgeInAdminBar.title")}
          </SecondaryHeading>
          <p className="body-text text-theme-secondary mt-1-5">
            {t(
              "siteSetting.environments.showEnvironmentBadgeInAdminBar.description"
            )}
          </p>
          <ToggleCard
            value={inputValues.show_environment_badge}
            setValue={(value) =>
              handleChangeInput("show_environment_badge", value)
            }
            isLoading={isLoading || loadingData}
          />
          <SecondaryHeading className="mt-4">
            {t("siteSetting.environments.overrideDefaultEnvironment.title")}
          </SecondaryHeading>
          <p className="body-text mb-1-5 mt-1-5 text-theme-secondary">
            {t(
              "siteSetting.environments.overrideDefaultEnvironment.description"
            )}
          </p>
          <SelectField
            value={inputValues.override_the_default_environment}
            setValue={(value) =>
              handleChangeInput("override_the_default_environment", value)
            }
            isLoading={isLoading}
          >
            <option value="Default Environment">
              {t(
                "siteSetting.environments.overrideDefaultEnvironment.defaultEnvironment"
              )}
            </option>
            <option value="Production">
              {t(
                "siteSetting.environments.overrideDefaultEnvironment.production"
              )}
            </option>
            <option value="Staging">
              {t("siteSetting.environments.overrideDefaultEnvironment.staging")}
            </option>
            <option value="Development">
              {t(
                "siteSetting.environments.overrideDefaultEnvironment.development"
              )}
            </option>
          </SelectField>
          <Divider height={2} className="mt-6 mb-4" />
          <EnvironmentsComponent
            color={inputValues.production_color}
            inputLabel={inputValues.production_label}
            title={t("siteSetting.environments.production.title")}
            onColorChange={(value) =>
              handleChangeInput("production_color", value)
            }
            onLabelChange={(value) =>
              handleChangeInput("production_label", value)
            }
          />
          <Divider height={2} className="mt-3 mb-4" />
          <div>
            <EnvironmentsComponent
              color={inputValues.staging_color}
              inputLabel={inputValues.staging_label}
              title={t("siteSetting.environments.staging.title")}
              onColorChange={(value) =>
                handleChangeInput("staging_color", value)
              }
              onLabelChange={(value) =>
                handleChangeInput("staging_label", value)
              }
            />
          </div>
          <Divider height={2} className="mt-3 mb-4" />
          <div>
            <EnvironmentsComponent
              color={inputValues.development_color}
              inputLabel={inputValues.development_label}
              title={t("siteSetting.environments.development.title")}
              onColorChange={(value) =>
                handleChangeInput("development_color", value)
              }
              onLabelChange={(value) =>
                handleChangeInput("development_label", value)
              }
            />
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
