import { DeleteIcon } from "allIcons";
import { DeleteModal, MainButton, SecondaryHeading } from "commonUI";
import { t } from "i18next";
import { useState } from "react";

export default function DeleteButton({
  show = true,
  onDelete,
  deleteMessage,
  deleteTitle = t("common.deletePopup.title"),
  deleteConfirmations,
  deleteButtonText = "Confirm",
  type = "icon",
  className = "",
  title = t("common.deleteButton.title"),
  message = t("common.deleteButton.description"),
  buttonText = t("common.deleteButton.title"),
  position = "bottom",
  style = {},
  toolTip = {},
  isDeleteButton = true,
  onClose = () => {},
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  return (
    <>
      {type !== "icon" ? (
        <div className={className ?? ""}>
          <SecondaryHeading>{title}</SecondaryHeading>
          <p className="body-text mb-2 mt-1-5">{message}</p>
        </div>
      ) : (
        ""
      )}
      {show ? (
        <div className="relative w-max">
          {type === "icon" ? (
            <DeleteIcon
              onClick={() => setOpenDeleteModal(true)}
              className="cursor-pointer"
            />
          ) : (
            <MainButton
              className="delete-button-red"
              onClick={() => setOpenDeleteModal(true)}
            >
              <DeleteIcon fill="white" />
              {buttonText}
            </MainButton>
          )}
          <DeleteModal
            position={position}
            style={style}
            toolTip={toolTip}
            setOpen={setOpenDeleteModal}
            open={openDeleteModal}
            isDeleteButton={isDeleteButton}
            deleteMessage={
              deleteMessage ??
              t("common.deletePopup.description")
            }
            deleteTitle={deleteTitle}
            deleteButtonText={deleteButtonText}
            deleteConfirmations={deleteConfirmations}
            onDelete={onDelete}
            onClose={onClose}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
