import { axiosInstance, useSearch, useUtilities } from "helpers";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getTags } from "redux/reducers/siteSettings/tagsReducer";

export async function getAllTags(web, system_status) {
  return axiosInstance
    .get(`allTags`, {
      params: {
        apiKey: localStorage.getItem("apikey"),
        website_id: web,
        system_status,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function deleteTag(id) {
  return axiosInstance
    .post(`deltag?tag_id=${id}&apiKey=${localStorage.getItem("apikey")}`)
    .then((res) => ({ ...res.data, id }))
    .catch((err) => err);
}

export async function updateTag({ id, ...data }) {
  return axiosInstance
    .post("updatetag", {
      apiKey: localStorage.getItem("apikey"),
      tag_id: id,
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function addTag(data) {
  return axiosInstance
    .post("addtag", {
      tag_by: localStorage.getItem("user_id"),
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function useGetTag(web = "", system_status = 0) {
  return useQuery(["tagList", web, system_status], () =>
    getAllTags(web, system_status)
  );
}

export function useTags(system_status = 0) {
  const { web = null } = useSearch();
  const {
    dispatch,
    tags: { tags, loaded, website_id, system_status: systemStatus },
  } = useUtilities();
  const { isLoading, error, data: tagsData } = useGetTag(web, system_status);

  useEffect(() => {
    if (
      tagsData &&
      (website_id !== web || !loaded || systemStatus !== system_status)
    ) {
      dispatch(
        getTags({
          tags: tagsData.Data,
          website_id: web,
          system_status,
        })
      );
    }
  }, [isLoading, error, tagsData, web, website_id]);

  return { data: tags, isLoading: isLoading || !loaded, error, error };
}
