import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackArrow, CircleIcon, SaveIcon } from "allIcons";
import {
  useDefaultPhases,
  usePhases,
  useUpdatePhase,
} from "api/templateLibrary/phases";
import {
  BodyContainer,
  HeaderContainer,
  IconWrapper,
  InputField,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  TextEditor,
} from "commonUI";
import { useAddNewPhase } from "api/templateLibrary/phases";
import {
  getUserSavePermission,
  isEqual,
  parse,
  useSearch,
  useUtilities,
} from "helpers";
import { useGetStatus } from "api/siteSettings/status";
import { NotificationType } from "enums";
import { useUserPermissions } from "hooks/user";
import DateInput from "commonUI/DateInput";
import { t } from "i18next";

export default function AddEditPhase() {
  const { id } = useParams();
  const { web = null } = useSearch();
  const { navigate, notification } = useUtilities();
  const [values, setValues] = useState({
    title: "",
    description: "",
    target_date: "",
    target_date_days: 10,
    status: {
      id: null,
      text: "Upcoming",
      color: "#7846FF",
    },
  });
  const { data: status } = useGetStatus("phases");
  const { data: phases, isLoading: loadingPhases } = usePhases();
  const { data: defaultPhases, isLoading: loadingDefaultPhases } =
    useDefaultPhases();
  const { mutateAsync: addPhase, isLoading: isAdding } = useAddNewPhase(
    "/site-settings/phases/",
    true
  );
  const { add_new_items_to_site_template, edit_item_details_in_site_template } =
    useUserPermissions();
  const { mutateAsync: updatePhase, isLoading: isUpdating } = useUpdatePhase();

  const handleSaveChanges = () => {
    if (!values.title) {
      notification({
        type: NotificationType.ERROR,
        message: t("siteSetting.phases.error.enterTitle"),
      });
      return;
    }
    if (web && !values.target_date) {
      notification({
        type: NotificationType.ERROR,
        message: t("siteSetting.phases.error.selectTargetDate"),
      });
      return;
    }
    if (isEqual(id, "add")) {
      addPhase({
        ...values,
        website_id: web,
        default_tem: web ? 0 : 1,
        target_date: values.target_date ?? "",
      });
      return;
    }

    const phase =
      phases.filter((ele) => isEqual(ele.id, id))[0] ||
      defaultPhases.filter((ele) => isEqual(ele.id, id))[0];
    updatePhase({
      ...phase,
      ...values,
      target_date: values.target_date ?? "",
    });
  };

  useEffect(() => {
    if (isEqual(id, "add") || loadingDefaultPhases || loadingPhases || isAdding)
      return;

    const phase =
      phases?.filter((ele) => isEqual(ele.id, id))[0] ||
      defaultPhases?.filter((ele) => isEqual(ele.id, id))[0];
    if (!phase) {
      navigate("/site-settings/phases");
      return;
    }
    const phaseStatus = parse(phase.status);

    setValues({
      title: phase?.title ?? "",
      description: phase?.description ?? "",
      target_date_days: phase?.target_date_days ?? 10,
      target_date: phase?.target_date
        ? new Date(phase?.target_date).toLocaleDateString("en-US")
        : "",
      status: {
        id: phaseStatus?.id ?? null,
        text: phaseStatus?.text ?? "Upcoming",
        color: phaseStatus?.color ?? "#7846FF",
      },
    });
  }, [
    loadingPhases,
    phases,
    id,
    defaultPhases,
    loadingDefaultPhases,
    isAdding,
  ]);

  return (
    <>
      <RightMainContainer>
        <Loader loader="block" show={isAdding || isUpdating} />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(`/site-settings/phases${web ? `?web=${web}` : ""}`)
              }
            />
            <PrimaryHeading>
              {isEqual(id, "add") ? t("siteSetting.phases.addPhase") : values.title}
            </PrimaryHeading>
          </IconWrapper>
          {getUserSavePermission({
            id,
            addPermission: add_new_items_to_site_template,
            editPermission: edit_item_details_in_site_template,
          }) ? (
            <MainButton onClick={handleSaveChanges}>
              <SaveIcon />{t("button.saveChanges")}
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label={t("siteSetting.phases.input.title")}
            value={values.title}
            setValue={(val) => setValues({ ...values, title: val })}
          />
          <TextEditor
            label={t("siteSetting.phases.input.description")}
            option={false}
            rows={5}
            value={values.description}
            setValue={(val) => setValues({ ...values, description: val })}
          />
          <br />
          <SelectField
            label={t("siteSetting.phases.input.status")}
            icon={<CircleIcon fill={values.status.color} />}
            placeholder={values.status.text}
            setValue={(val) => {
              const statusDetails = status?.Data?.filter((ele) =>
                isEqual(ele.id, val)
              )[0];
              setValues({
                ...values,
                status: {
                  id: val,
                  text: statusDetails.status_title,
                  color: statusDetails.color,
                },
              });
            }}
            value={values.status.id}
          >
            {status?.Data?.map((ele) => (
              <option value={ele.id}>{ele.status_title}</option>
            ))}
          </SelectField>
          {!web ? (
            <InputField
              label={t("siteSetting.phases.input.phaseDurationDays")}
              type="number"
              value={values.target_date_days}
              setValue={(val) =>
                setValues({ ...values, target_date_days: val })
              }
            />
          ) : (
            ""
          )}
          {web ? (
            <DateInput
              label={t("siteSetting.phases.input.targetEndDate")}
              placeholder="mm/dd/yyyy"
              value={values.target_date}
              setValue={(val) => setValues({ ...values, target_date: val })}
            />
          ) : (
            ""
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
