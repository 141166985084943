import { Children } from "react";
import { useState, useEffect, useDeferredValue } from "react";
import {
  HeaderContainer,
  MainButton,
  RightMainContainer,
  PrimaryHeading,
  BodyContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  PopOver,
  AddFromTemplateLibrary,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  FiltersList,
} from "commonUI";
import { AddIcon, NotesIcon } from "allIcons";
import {
  useAddDefaultNote,
  useRemoveDefaultNote,
  useTemplateNotes,
  useDefaultNotes,
} from "api/templateLibrary/notes";
import { search } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useTags } from "api/siteSettings/Tags";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function Notes() {
  const {
    data: notes,
    isLoading: notesLoading,
    error: notesError,
  } = useTemplateNotes();
  const [addPopUp, setAddPopUp] = useState(false);

  const {
    data: defaultNotes,
    isLoading: defaultNotesLoading,
    error: defaultNotesError,
  } = useDefaultNotes();
  const [filteredDefaultNotes, setFilteredDefaultNotes] = useState([]);

  const addMutation = useAddDefaultNote();
  const removeMutation = useRemoveDefaultNote();
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [filterData, setFilterData] = useState({});
  const {
    remove_items_from_site_template,
  } = useUserPermissions();

  useEffect(() => {
    setFilteredDefaultNotes(
      defaultNotes.filter((note) => search(note.title, defferedSearch))
    );
  }, [defaultNotes, defferedSearch]);

  const applySorting = (order, orderBy) => {
    setFilteredDefaultNotes((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setFilteredDefaultNotes(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        defaultNotes
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {addMutation.isLoading || removeMutation.isLoading ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <PrimaryHeading>Notes</PrimaryHeading>
          <div className="relative">
            <MainButton onClick={() => setAddPopUp(!addPopUp)}>
              <AddIcon fill="white" />
              Add
            </MainButton>
            <PopOver open={addPopUp} onClose={() => setAddPopUp(false)}>
              <AddFromTemplateLibrary
                data={
                  notes
                    .filter(
                      (note) =>
                        note.default_tem === null || note.default_tem === "0"
                    )
                    .map((note) => ({
                      ...note,
                      icon: <NotesIcon fill="white" />,
                      onClick: () =>
                        addMutation.mutate({
                          id: note.id,
                        }),
                    })) || []
                }
                emptyTemplate={{
                  label: "Notes",
                  link: "/template-library/notes/add",
                  linkLabel: "Note",
                }}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(value) => setSearchText(value)}
            showLibrarySource={false}
            tags={tags}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {defaultNotesLoading ? <LoadingListCards /> : ""}
          {Children.toArray(
            filteredDefaultNotes.length > 0 ? (
              filteredDefaultNotes.map((note, index) => (
                <ListCard
                  editIcon={false}
                  data={note.title}
                  onCross={() => {
                    removeMutation.mutate(note.id);
                  }}
                  icon={
                    <ListIconWrapper>
                      <NotesIcon fill="white" />
                    </ListIconWrapper>
                  }
                  deleteIcon
                  crossIcon={remove_items_from_site_template}
                  showModalonCross
                  deleteTitle="Remove This Template?"
                  deleteButtonText="Confirm"
                  deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                />
              ))
            ) : !defaultNotesLoading ? (
              <TemplateNotFound
                label="Notes"
                linkLabel="Note"
                onClick={() => setAddPopUp(true)}
              />
            ) : (
              ""
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
