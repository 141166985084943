import { axiosInstance, useUtilities } from "helpers";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getTemplateTasks } from "redux/reducers/templateLibrary/tasks";

export async function getAllTasks() {
  return axiosInstance
    .post("defaulttaskshow", {
      apikey: localStorage.getItem("apikey"),
      parent_type: "task",
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function deleteTask(id) {
  return axiosInstance
    .post(`defaulttaskdelete`, {
      apikey: localStorage.getItem("apikey"),
      id: id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => err);
}

export async function updateTask({ title, tags, id, ...rest }) {
  return axiosInstance
    .post(`defaulttaskedit`, {
      apikey: localStorage.getItem("apikey"),
      title: title,
      id: id,
      tags: tags,
      ...rest,
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function addTask(data) {
  return axiosInstance
    .post(`addlibrarytemplate`, {
      ...data,
      apikey: localStorage.getItem("apikey"),
      parent_type: "task",
      module_hint: "task",
      wpsl_tem: localStorage.getItem("apikey"),
      system_status: "0",
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function getWpslTasks() {
  return axiosInstance
    .post("getwpsltasktemp", {
      apikey: localStorage.getItem("apikey"),
      parent_type: "task",
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}
export async function addTemplateTask(data) {
  return axiosInstance
    .post("adddefaulttemplate", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function removeDefaultTask(id) {
  return axiosInstance
    .post("defaulttaskremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useGetWpslTasks() {
  return useQuery(["wpslTasksList"], getWpslTasks);
}

export function useGetTasks() {
  return useQuery(["tasksList"], getAllTasks);
}

export function useTasks() {
  const { data, isLoading, error } = useGetTasks();
  const {
    dispatch,
    siteTasks: { tasks, loaded },
  } = useUtilities();

  useEffect(() => {
    if (loaded) return;
    if (data) {
      dispatch(getTemplateTasks(data.results));
    }
  }, [data, loaded]);
  return { data: tasks, isLoading: isLoading || !loaded, error };
}
