import { Children, useDeferredValue, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ListCard,
  MainButton,
  HeaderContainer,
  PrimaryHeading,
  ListIconWrapper,
  RightMainContainer,
  BodyContainer,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  InputField,
  FiltersList,
} from "commonUI";
import { AddIcon, PluginListIcon } from "allIcons";
import {
  usePluginList,
  useDeletePluginList,
  useAddPluginList,
} from "api/templateLibrary/pluginList";
import { parse, search } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function PluginLists() {
  const { data: pluginList, isLoading } = usePluginList();
  const { mutate: deletePluginList, isLoading: isDeleting } =
    useDeletePluginList(false);

  const { mutate: duplicatePluginList, isLoading: isDuplicating } =
    useAddPluginList(false);

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    setSearchResult(
      pluginList.filter((task) => search(task.list_name, defferedSearch))
    );
  }, [defferedSearch, pluginList]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult, "list_name")
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        pluginList
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {isDeleting || isDuplicating ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <PrimaryHeading>Plugin Lists</PrimaryHeading>
          {add_new_templates ? (
            <Link to="add">
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
            sortOptions={[
              {
                label: t("sort.options.alphabeticalByTitle"),
                value: "alphabetical-by-title",
              },
            ]}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          <div className="">
            {isLoading ? (
              <LoadingListCards />
            ) : searchResult.length === 0 ? (
              <TemplateNotFound
                label="PluginLists"
                link={add_new_templates ? "add" : ""}
                linkLabel="PluginList"
              />
            ) : (
              Children.toArray(
                searchResult.map((iner, index) => {
                  return (
                    <ListCard
                      data={iner.list_name}
                      key={index}
                      index={index}
                      link={
                        edit_templates
                          ? `/template-library/plugin-lists/${iner.id}`
                          : ""
                      }
                      editIcon={edit_templates}
                      strictLink={true}
                      duplicate={add_new_templates}
                      onDuplicate={() =>
                        duplicatePluginList({
                          list_name: "Copy of " + iner.list_name,
                          plugin_list: parse(iner.plugin_list),
                          tags: parse(iner.tags),
                        })
                      }
                      deleteIcon={!delete_templates}
                      onDelete={() => deletePluginList(iner.id)}
                      icon={
                        <ListIconWrapper>
                          <PluginListIcon />
                        </ListIconWrapper>
                      }
                      deleteTitle="Delete This Template?"
                      deleteButtonText="Confirm Delete"
                      deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                    />
                  );
                })
              )
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
