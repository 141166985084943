import { Link, useParams, useNavigate } from "react-router-dom";
import { useGetStatus, deleteStatus } from "api/siteSettings/status";
import { useEffect, useState } from "react";
import { queryClient } from "client";
import { useMutation } from "@tanstack/react-query";
import {
  Loader,
  LoadingListCards,
  TemplateNotFound,
  IconWrapper,
  ListIconWrapper,
  BodyContainer,
  MainButton,
  PrimaryHeading,
  HeaderContainer,
  ListCard,
  RightMainContainer,
} from "commonUI";
import { BackArrow, AddIcon, StatusIcon } from "allIcons";
import { useNotification } from "notification";
import { isEqual, printError, useSearch } from "helpers";
import { useUserPermissions, useUserRole } from "hooks/user";
import { ApiResponseType, NotificationType } from "enums";
import { t } from "i18next";

export default function StatusOptions() {
  const { web = null } = useSearch();
  const { option } = useParams();
  const { isLoading, data } = useGetStatus(option);
  const navigate = useNavigate();
  const [status, setStatus] = useState([]);
  const notification = useNotification();
  const { isSuperAdmin } = useUserRole();
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  useEffect(() => {
    if (isLoading) return;
    setStatus(
      data?.Data?.filter((ele) =>
        isEqual(ele.parent_type.toLowerCase(), option)
      )
    );
  }, [isLoading, option, data]);

  const deleteMutation = useMutation(deleteStatus, {
    onSuccess: (data) => {
      if (data?.StatusCode === ApiResponseType.SUCCESS) {
        queryClient.invalidateQueries([`status-${option}`, web]);
        notification({
          type: NotificationType.SUCCESS,
          message: t("siteSetting.status.module.notification.deleted", {
            module: t(`siteSetting.status.${option.slice(
              0,
              option.length - 1
            )}`)
          })
        });
      } else {
        notification({
          type: "error",
          message: printError(data?.Message),
        });
      }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: NotificationType.ERROR,
        message: t("common.error.somethingWentWrongTryLater"),
      });
    },
  });

  const getDeletePermission = (ele) => {
    if (isSuperAdmin) return false;
    if (isEqual(ele.system_status, 0)) {
      return true;
    }
    if (!web || !remove_items_from_site_template) {
      return true;
    }
    return false;
  };
  const getEditPermission = (ele) => {
    if (isSuperAdmin) return true;
    if (isEqual(ele.system_status, 1) && edit_item_details_in_site_template) {
      return true;
    }
    if (!edit_item_details_in_site_template) {
      return false;
    }
    return false;
  };

  return (
    <>
      {deleteMutation.isLoading && <Loader loader="block" />}
      <RightMainContainer>
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(`/site-settings/status${web ? `?web=${web}` : ""}`)
              }
            />
            <PrimaryHeading>
              {t("siteSetting.status.module.options.title", {
                module: t(`siteSetting.status.${option}`),
              })}
            </PrimaryHeading>
          </IconWrapper>
          {add_new_items_to_site_template ? (
            <Link to={`add${web ? `?web=${web}` : ""}`}>
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {isLoading ? (
              <LoadingListCards />
            ) : status.length > 0 ? (
              status?.map((iner, index) => {
                return (
                  <ListCard
                    secondaryData={
                      isSuperAdmin
                        ? ""
                        : isEqual(iner.system_status, 1)
                        ? t("module.sourceType.custom")
                        : t("module.sourceType.system")
                    }
                    key={"status-" + index}
                    index={index}
                    data={iner.status_title}
                    link={
                      edit_item_details_in_site_template || isSuperAdmin
                        ? `/site-settings/status/${option}/${iner.id}`
                        : ""
                    }
                    defaultLink={
                      edit_item_details_in_site_template
                        ? `/site-settings/status/${option}${
                            web ? `?web=${web}` : ""
                          }`
                        : ""
                    }
                    onClick={
                      isEqual(iner.system_status, 1) && isSuperAdmin
                        ? null
                        : () => {}
                    }
                    strictLink={true}
                    deleteIcon={getDeletePermission(iner)}
                    editIcon={getEditPermission(iner)}
                    onDelete={() => {
                      deleteMutation.mutate(iner.id);
                    }}
                    crossIcon={isEqual(iner.system_status, 1)}
                    showModalonCross
                    icon={
                      <ListIconWrapper>
                        <StatusIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle={
                      web
                        ? t("siteSetting.status.module.confirmDelete.forSite.title")
                        : t("siteSetting.status.module.confirmDelete.title")
                    }
                    deleteButtonText={
                      web
                        ? t("siteSetting.status.module.confirmDelete.forSite.buttonText")
                        : t("siteSetting.status.module.confirmDelete.buttonText")
                    }
                    deleteMessage={
                      web
                        ? t("siteSetting.status.module.confirmDelete.forSite.message")
                        : t("siteSetting.status.module.confirmDelete.message")
                    }
                  />
                );
              })
            ) : (
              <TemplateNotFound
                label={t(`siteSetting.status.${option}`)}
                linkLabel={t(`siteSetting.status.${option}`)}
                link={
                  add_new_items_to_site_template
                    ? `/site-settings/status/${option}/add${
                        web ? `?web=${web}` : ""
                      }`
                    : ""
                }
              />
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
