import { useEffect, useMemo, useState } from "react";
import {
  ListCard,
  PrimaryHeading,
  HeaderContainer,
  DropDownCard,
  AddComponent,
  ListIconWrapper,
  RightMainContainer,
  BodyContainer,
  PopOver,
  MainButton,
  Loader,
  LoadingListCards,
} from "commonUI";
import {
  PhaseIcon,
  AssignmentIcon,
  StartupIcon,
  CheckListIcon,
  SaveIcon,
  OpenLinkIcon,
} from "allIcons";
import { isEmpty, parse, useSearch } from "helpers";
import {
  useLaunchpadShortcuts,
  useUpdateLaunchpadShortcuts,
} from "hooks/launchpadShortcuts";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";


export default function LaunchpadWidgets() {
  const { web = null } = useSearch();
  const { data, isLoading } = useLaunchpadShortcuts();
  const [showAddComponent, setShowAddComponent] = useState({
    open: false,
    position: "top",
  });
  const {
    mutateAsync: updateLaunchpadShortcuts,
    isLoading: updatingLaunchpadSetting,
  } = useUpdateLaunchpadShortcuts();
  const [shortcuts, setShotcuts] = useState({});

  const shortcutModules = useMemo(() => ({
    assignments: { title: t("siteSetting.launchpadWidgets.module.assignments"), icon: <AssignmentIcon fill="white" /> },
    onboarding_checklist: {
      title: t("siteSetting.launchpadWidgets.module.onboarding"),
      icon: <CheckListIcon fill="white" />,
    },
    phases: { title: t("siteSetting.launchpadWidgets.module.projectStatus"), icon: <PhaseIcon fill="white" /> },
    startup_tools: {
      title: t("siteSetting.launchpadWidgets.module.startupActions"),
      icon: <StartupIcon fill="white" />,
    },
    Shortcuts: {
      title: t("siteSetting.launchpadWidgets.module.shortcuts"),
      icon: <OpenLinkIcon fill="white" />,
    },
    // Here for future use
    // users: { title: t("siteSetting.launchpadWidgets.module.users"), icon: <UserIcon fill="white" /> },
    // activity: { title: t("siteSetting.launchpadWidgets.module.activity"), icon: <StatusIcon fill="white" /> },
    // project_brief: {
    //   title: t("siteSetting.launchpadWidgets.module.projectBrief"),
    //   icon: <ProjectBriefIcon fill="white" />,
    // },
  }),[]);

  const [addValues, setAddValues] = useState([]);
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  useEffect(() => {
    if (isLoading) return;
    if (!isEmpty(data)) {
      const parsedData = parse(data.launchpad_settings);
      let parsedModuleData = [];
      let addModuleData = [];
      parsedData?.forEach((key) => {
        const property = Object.keys(key)[0];
        if (!shortcutModules[property]) return;
        if (key[property]) {
          parsedModuleData.push({
            [property]: shortcutModules[property],
          });
        } else {
          addModuleData.push({
            [property]: shortcutModules[property],
          });
        }
      });
      setShotcuts({
        ...data,
        launchpad_settings: parsedModuleData,
      });
      setAddValues(addModuleData);
    } else {
      setShotcuts({
        launchpad_settings: [],
      });
      setAddValues(
        Object.keys(shortcutModules).map((key) => ({
          [key]: shortcutModules[key],
        }))
      );
    }
  }, [data, isLoading]);

  const handleSave = () => {
    let settings = [];
    let settingModules = shortcuts.launchpad_settings.map(
      (key) => Object.keys(key)[0]
    );

    Object.keys(shortcutModules).forEach((key) => {
      if (settingModules.includes(key)) {
        settings.push({
          [key]: true,
        });
      } else {
        settings.push({
          [key]: false,
        });
      }
    });
    updateLaunchpadShortcuts({
      launchpad_settings: settings,
      website_id: web,
    });
  };
  return (
    <>
      <RightMainContainer>
        <Loader show={updatingLaunchpadSetting} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>
            {t("siteSetting.launchpadWidgets.title")}
          </PrimaryHeading>
          {edit_item_details_in_site_template ? (
            <MainButton onClick={handleSave}>
              <SaveIcon />
              {t("button.saveChanges")}
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {isLoading ? (
              <LoadingListCards />
            ) : (
              shortcuts?.launchpad_settings?.map((key, index) => {
                return (
                  <ListCard
                    data={Object.values(key)[0].title}
                    key={index}
                    index={index}
                    editIcon={false}
                    deleteIcon={true}
                    crossIcon={remove_items_from_site_template && !isLoading}
                    showModalonCross
                    icon={
                      <ListIconWrapper>
                        {Object.values(key)[0].icon}
                      </ListIconWrapper>
                    }
                    onCross={() => {
                      if (!remove_items_from_site_template || isLoading) return;
                      setShotcuts((pre) => ({
                        ...pre,
                        launchpad_settings: pre.launchpad_settings.filter(
                          (item) => item !== key
                        ),
                      }));
                      setAddValues((pre) => {
                        return [...pre, key];
                      });
                    }}
                    deleteButtonText={
                      web
                        ? t("siteSetting.launchpadWidgets.removeConfirmation.forSite.button")
                        : t("siteSetting.launchpadWidgets.removeConfirmation.button")
                    }
                    deleteTitle={
                      web
                        ? t("siteSetting.launchpadWidgets.removeConfirmation.forSite.title")
                        : t("siteSetting.launchpadWidgets.removeConfirmation.title")
                    }
                    deleteMessage={
                      web
                        ? t("siteSetting.launchpadWidgets.removeConfirmation.forSite.message")
                        : t("siteSetting.launchpadWidgets.removeConfirmation.message")
                    }
                  />
                );
              })
            )}
            {add_new_items_to_site_template ? (
              <AddComponent
                title={t("siteSetting.launchpadWidgets.addWidget")}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAddComponent({
                    open: true,
                    position:
                      Object.keys(addValues).length >= 3 ? "bottom" : "top",
                  });
                }}
              >
                <PopOver
                  open={showAddComponent.open}
                  onClose={() =>
                    setShowAddComponent({
                      open: false,
                      position: "top",
                    })
                  }
                  className="absolute bottom-full mb-2"
                  style={{
                    width: "270px",
                    right: "auto",
                    top:
                      showAddComponent.position === "top"
                        ? "auto"
                        : "calc(100% - 10px)",
                    bottom:
                      showAddComponent.position === "top"
                        ? "calc(100% + 10px)"
                        : "auto",
                  }}
                  toolTip={{
                    horizontal: "center",
                    vertical:
                      showAddComponent.position === "top" ? "bottom" : "top",
                  }}
                >
                  <DropDownCard
                    className="relative-important p-0"
                    list={addValues.map((key) => ({
                      key: Object.keys(key)[0],
                      title: Object.values(key)[0].title,
                      icon: (
                        <ListIconWrapper className="text-white">
                          {Object.values(key)[0].icon}
                        </ListIconWrapper>
                      ),
                    }))}
                    onSelect={(ele) => {
                      if (
                        shortcuts.launchpad_settings.findIndex(
                          (item) => Object.keys(item)[0] === ele.key
                        ) !== -1
                      ) {
                        return;
                      }
                      const newAddValues = [...addValues];
                      newAddValues.splice(
                        newAddValues.findIndex(
                          (item) => Object.keys(item)[0] === ele.key
                        ),
                        1
                      );
                      setAddValues(newAddValues);
                      setShotcuts((pre) => ({
                        ...pre,
                        launchpad_settings: [
                          ...pre.launchpad_settings,
                          {
                            [ele.key]: shortcutModules[ele.key],
                          },
                        ],
                      }));
                    }}
                  />
                </PopOver>
              </AddComponent>
            ) : (
              ""
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
