import { useEffect, useState } from "react";
import {
  BodyContainer,
  ColorPicker,
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  InputField,
  SecondaryHeading,
  MainButton,
  Loader,
  LoadingCard,
  LimitReachedCard,
  Divider,
} from "commonUI";
import { SaveIcon, AddIcon, CrossIcon } from "allIcons";
import {
  useBrandingAndColors,
  useUpdateBrandingAndColors,
} from "hooks/brandingAndColors";
import {
  isEmpty,
  parse,
  getBrandingLogo,
  useUtilities,
  isEqual,
  useSearch,
} from "helpers";
import { NotificationType } from "enums";
import { uploadFiles } from "api/util";
import useUserPlan from "hooks/plan";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export const ColorComponent = ({
  title,
  value,
  onChange,
  color,
  children,
  description,
  showInput,
  placeholder,
  isLoading = false,
}) => {
  return (
    <>
      <div className="flex flex-col gap-7">
        <SecondaryHeading>{title}</SecondaryHeading>
        {description ? (
          <p className="body-text text-theme-secondary m-0">{description}</p>
        ) : (
          ""
        )}
        {showInput && (
          <InputField
            placeholder={placeholder ?? `Write ${title} Here`}
            value={value}
            setValue={onChange}
            className="!m-0"
            inputDivClass="mt-0 mb-0"
            isLoading={isLoading}
          />
        )}
        {color && <ColorPicker value={value} setValue={onChange} />}
        {children}
      </div>
    </>
  );
};

export default function BrandingAndColor() {
  const { data, isLoading } = useBrandingAndColors();
  const [inputValues, setInputValues] = useState({});
  const { notification } = useUtilities();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const whiteLabelaAllowed = isEqual(wpslPlan.white_label, 1);
  const {
    mutateAsync: updateBrandingAndColor,
    isLoading: updatingBrandingAndColor,
  } = useUpdateBrandingAndColors();
  const { edit_item_details_in_site_template } = useUserPermissions();
  const { web = null } = useSearch();

  useEffect(() => {
    if (!isEmpty(data)) {
      setInputValues(parse(data.brand_colors_settings));
    }
  }, [data]);

  const handleInputChange = (key, value) => {
    setInputValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSiteLogo = (event) => {
    const file = event.target.files[0];
    if (!file.type.includes("image/")) {
      notification({
        type: "error",
        message: t(
          "siteSetting.brandingAndColors.input.error.fileTypeNotAllowed",
          {
            fileType: file.type.split("/").pop(),
          }
        ),
      });
      return;
    }
    setInputValues((pre) => ({
      ...pre,
      product_logo: event.target.files[0] ?? "",
    }));
  };

  const handleSaveChanges = async () => {
    const inputData = { ...inputValues };
    if (!inputData.product_name) {
      notification({
        type: NotificationType.ERROR,
        message: t("siteSetting.brandingAndColors.input.error.nameRequired"),
      });
    }
    if (typeof inputData.product_logo === "object") {
      const logoString = await uploadFiles({
        product_logo: inputData.product_logo,
        website_id: web,
      });
      inputData.product_logo = logoString.Data;
    }
    updateBrandingAndColor(inputData);
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={updatingBrandingAndColor} loader="block" />
        <HeaderContainer>
          <PrimaryHeading className="leading-6">
            {t("siteSetting.brandingAndColors.title")}
          </PrimaryHeading>
          {(!whiteLabelaAllowed || !edit_item_details_in_site_template) &&
          !loadingPlans ? (
            ""
          ) : (
            <MainButton onClick={handleSaveChanges}>
              <SaveIcon />{t("button.saveChanges")}
            </MainButton>
          )}
        </HeaderContainer>
        <BodyContainer>
          {!whiteLabelaAllowed && !loadingPlans ? (
            <LimitReachedCard
              show
              title={t("siteSetting.brandingAndColors.accessLimit.title")}
              description={t(
                "siteSetting.brandingAndColors.accessLimit.description"
              )}
            />
          ) : (
            <>
              <PrimaryHeading className="text-17">
                {t("siteSetting.brandingAndColors.branding.title")}
              </PrimaryHeading>
              <div className="flex flex-col gap-15 mt-3">
                <ColorComponent
                  value={inputValues.product_name}
                  isLoading={isLoading}
                  onChange={(value) => handleInputChange("product_name", value)}
                  showInput={true}
                  placeholder={t(
                    "siteSetting.brandingAndColors.branding.productName.placeholder"
                  )}
                  title={t(
                    "siteSetting.brandingAndColors.branding.productName.title"
                  )}
                  description={t(
                    "siteSetting.brandingAndColors.branding.productName.description"
                  )}
                />
                <ColorComponent
                  title={t(
                    "siteSetting.brandingAndColors.branding.productLogo.title"
                  )}
                  description={t(
                    "siteSetting.brandingAndColors.branding.productLogo.description"
                  )}
                >
                  <label
                    className="logo-input cursor-default mt-1-5"
                    onClick={() => {
                      document.getElementById("logo-input").value = "";
                    }}
                  >
                    {isLoading ? (
                      <LoadingCard />
                    ) : inputValues.product_logo ? (
                      <div className="relative">
                        <img
                          className="logo-input-preview"
                          src={getBrandingLogo(inputValues.product_logo)}
                        />
                        {edit_item_details_in_site_template ? (
                          <div
                            className="absolute top-0 right-0 logo-input-preview-background"
                            onClick={() =>
                              setInputValues((pre) => ({
                                ...pre,
                                product_logo: "",
                              }))
                            }
                          >
                            <CrossIcon fill="gray" size="10" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <>
                        <label
                          htmlFor={"logo-input"}
                          className="cursor-pointer flex flex-col gap-5 items-center"
                        >
                          <div
                            className="rounded-5 bg-theme-blue flex items-center justify-center"
                            style={{ padding: "6px" }}
                          >
                            <AddIcon />
                          </div>
                          <SecondaryHeading className="text-placeholder-gray font-semibold text-13">
                            {t(
                              "siteSetting.brandingAndColors.branding.productLogo.addLogo"
                            )}
                          </SecondaryHeading>
                        </label>
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    name=""
                    id="logo-input"
                    hidden
                    onChange={handleSiteLogo}
                  />
                </ColorComponent>
              </div>
              <Divider height={2} className="mt-5 mb-3" />
              <PrimaryHeading className="text-17 mb-3">
                {t("siteSetting.brandingAndColors.colors.title")}
              </PrimaryHeading>
              <div className="flex flex-col gap-15">
                <ColorComponent
                  showInput={true}
                  title={t(
                    "siteSetting.brandingAndColors.colors.accentColor.title"
                  )}
                  isLoading={isLoading}
                  placeholder={t(
                    "siteSetting.brandingAndColors.colors.accentColor.placeholder"
                  )}
                  color
                  value={inputValues.accent_color || "#0066FF"}
                  onChange={(value) => handleInputChange("accent_color", value)}
                />
              </div>
            </>
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
