import { SecondaryHeading, InputField, PopOver } from "commonUI";
import { CrossIcon } from "allIcons";
import { t } from "i18next";

export default function DeleteModal({
  position = "bottom",
  style = {},
  toolTip = {},
  open = true,
  setOpen,
  inputValue,
  setInputValue,
  deleteTitle = t("common.deletePopup.title"),
  deleteMessage,
  deleteConfirmations,
  onDelete,
  deleteButtonText = t("common.deletePopup.deleteButton.yes"),
  isDeleteButton = false,
  onClose = () => {},
}) {
  const getButtonClass = () => {
    let className = "py-2 w-full mb-3 rounded-5 border-0";
    if (deleteConfirmations) {
      if (inputValue.trim() === deleteConfirmations) {
        className += " bg-theme-blue cursor-pointer";
      } else {
        className += " cursor-not-allowed bg-theme-gray";
      }
    } else if (isDeleteButton) {
      className += " delete-button-red cursor-pointer";
    } else {
      className += " bg-theme-blue cursor-pointer";
    }
    return className + " text-white font-semibold text-14 leading-5";
  };
  return (
    <PopOver
      open={open}
      style={
        position === "top"
          ? {
              width: "350px",
              bottom: "calc(100% + 15px)",
              top: "unset",
              ...style,
            }
          : { width: "350px", ...style }
      }
      toolTip={toolTip}
      onClose={() => {
        onClose();
        setOpen(false);
      }}
    >
      <div className="flex justify-between items-center ml-5 mr-5 mt-3 mb-3">
        <SecondaryHeading>{deleteTitle}</SecondaryHeading>
        <div className="">
          <CrossIcon
            fill="black"
            className="cursor-pointer"
            onClick={() => {
              onClose();
              setOpen(false);
            }}
          />
        </div>
      </div>
      <div className="ml-5 mr-5">
        <p className="body-text mb-4">{deleteMessage}</p>
        {deleteConfirmations && (
          <>
            <h2 className="body-text ml-2 mr-2 ">
              {t("common.deletePopup.confirmInput.label")}
            </h2>
            <div className=" rounded-5 mb-1">
              <InputField
                type="text"
                placeholder={t("common.deletePopup.confirmInput.placeholder")}
                value={inputValue}
                setValue={setInputValue}
              />
            </div>
          </>
        )}
        <button
          onClick={async() => {
            if (deleteConfirmations && inputValue.trim() !== deleteConfirmations) return;
            const isDeleted = await onDelete();
            if (!isDeleted) {
              setOpen(false);
            }
          }}
          type="submit"
          className={getButtonClass()}
        >
          {deleteButtonText}
        </button>
      </div>
    </PopOver>
  );
}
