import React, { useEffect, useState } from "react";
import { SaveIcon } from "allIcons";
import {
  AccordionBody,
  BodyContainer,
  Divider,
  HeaderContainer,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  ToggleCard,
} from "commonUI";
import { extractTextFromHtml, isEmpty, parse, useSearch } from "helpers";
import {
  useMaintenance,
  usePagesList,
  useUpdateMaintenanceSetting,
} from "hooks/maintenance";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function Maintenance() {
  const { data, isLoading } = useMaintenance();
  const { data: pagesList, isLoading: pagesLoading } = usePagesList();
  const { mutate: updateMaintenance, isLoading: isUpdating } =
    useUpdateMaintenanceSetting();
  const { web } = useSearch();
  const { edit_item_details_in_site_template } = useUserPermissions();

  const [inputValues, setInputValues] = useState({
    coming_soon: false,
    maintenance_mode: false,
    coming_soon_pageid: 0,
    maintenance_pageid: 0,
  });

  const handleInputChange = (key, value) => {
    if (key === "coming_soon" && value === true) {
      setInputValues((pre) => ({
        ...pre,
        maintenance_mode: false,
        [key]: value,
      }));
    } else if (key === "maintenance_mode" && value === true) {
      setInputValues((pre) => ({
        ...pre,
        coming_soon: false,
        [key]: value,
      }));
    } else {
      setInputValues((pre) => ({ ...pre, [key]: value }));
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setInputValues((pre) => parse(data?.maintenance_settings || "") || pre);
    }
  }, [data]);

  return (
    <>
      <RightMainContainer>
        <Loader show={isUpdating} loader="block" />
        <HeaderContainer>
          <PrimaryHeading className="leading-6">
            {t("siteSetting.maintenance.title")}
          </PrimaryHeading>
          {edit_item_details_in_site_template ? (
            <MainButton
              onClick={() =>
                updateMaintenance({
                  data: inputValues,
                  website_id: web,
                })
              }
              disabled={isLoading || pagesLoading}
            >
              <SaveIcon />{t("button.saveChanges")}
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <ToggleCard
            heading={t("siteSetting.maintenance.commingSoon.title")}
            label={t("siteSetting.maintenance.commingSoon.label")}
            description={t("siteSetting.maintenance.commingSoon.description")}
            value={inputValues.coming_soon}
            setValue={(value) => handleInputChange("coming_soon", value)}
          />
          <AccordionBody show={inputValues.coming_soon}>
            <SelectField
              className="mt-3"
              placeholder={t("siteSetting.maintenance.commingSoon.select.placeholder")}
              label={t("siteSetting.maintenance.commingSoon.select.title")}
              description={t("siteSetting.maintenance.commingSoon.select.description")}
              value={inputValues.coming_soon_pageid}
              setValue={(value) =>
                handleInputChange("coming_soon_pageid", value)
              }
            >
              {Array.isArray(pagesList?.Data)
                ? pagesList?.Data?.map((page) => (
                    <option value={page.page_id}>
                      {extractTextFromHtml(page.title)}
                    </option>
                  ))
                : ""}
            </SelectField>
          </AccordionBody>
          <Divider height={2} className="mb-3 mt-5" />
          <ToggleCard
            heading={t("siteSetting.maintenance.maintenance.title")}
            label={t("siteSetting.maintenance.maintenance.label")}
            description={t("siteSetting.maintenance.maintenance.description")}
            value={inputValues.maintenance_mode}
            setValue={(value) => handleInputChange("maintenance_mode", value)}
          />
          <AccordionBody show={inputValues.maintenance_mode}>
            <SelectField
              placeholder={t("siteSetting.maintenance.maintenance.select.placeholder")}
              label={t("siteSetting.maintenance.maintenance.select.title")}
              description={t("siteSetting.maintenance.maintenance.select.description")}
              value={inputValues.maintenance_pageid}
              setValue={(value) =>
                handleInputChange("maintenance_pageid", value)
              }
            >
              {Array.isArray(pagesList?.Data)
                ? pagesList?.Data?.map((page) => (
                    <option value={page.page_id}>
                      {extractTextFromHtml(page.title)}
                    </option>
                  ))
                : ""}
            </SelectField>
          </AccordionBody>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
