import { MoveableIcon } from "allIcons";
import IconContainer from "./IconContainer";
import { useSortable } from "@dnd-kit/sortable";

const DragElement = ({ className, ...props}) => {
  return (
    <>
      <IconContainer className={`cursor-move ${className}`}
      {...props}
      >
        <MoveableIcon />
      </IconContainer>
    </>
  );
};

export default DragElement;
