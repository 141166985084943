import { Children, useState } from "react";
import { ContractIcon, AddIcon } from "allIcons";
import {
  RightMainContainer,
  PrimaryHeading,
  MainButton,
  ListIconWrapper,
  ListCard,
  InputField,
  HeaderContainer,
  BodyContainer,
} from "commonUI";
import { t } from "i18next";

export default function ContractTemplates() {
  const [contractList, setContractList] = useState([
    "[Contract Template Title]",
    "[Contract Template Title]",
    "[Contract Template Title]",
    "[Contract Template Title]",
  ]);
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Contracts</PrimaryHeading>
          <MainButton>
            <AddIcon />
            Add
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <InputField placeholder={t("input.search.placeholder")} searchIcon={true} filter={true} />
          {Children.toArray(
            contractList.map((task, index) => {
              return (
                <ListCard
                  editIcon={false}
                  data={task}
                  onDelete={() => {
                    setContractList(() =>
                      contractList.filter((item, i) => i !== index)
                    );
                  }}
                  icon={
                    <ListIconWrapper>
                      <ContractIcon />
                    </ListIconWrapper>
                  }
                  deleteTitle="Delete This Template?"
                  deleteButtonText="Confirm Delete"
                  deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
