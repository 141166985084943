import { useEffect, useMemo, useState } from "react";
import {
  AddFromTemplateLibrary,
  ListCard,
  MainButton,
  HeaderContainer,
  PrimaryHeading,
  BodyContainer,
  RightMainContainer,
  ListIconWrapper,
  PopOver,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  PopUpListing,
  SecondaryHeading,
  IconContainer,
} from "commonUI";
import { AddIcon, PhaseIcon, CloudDownloadIcon } from "allIcons";
import {
  useAddDefaultPhase,
  useAddNewPhase,
  useDefaultPhases,
  useDeleteDefaultPhase,
  usePhases,
  useUpdatePhasesOrder,
} from "api/templateLibrary/phases";
import { isEmpty, isEqual, parse, useSearch, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";
import { SortableList } from "commonUI";

const phasesList = ["Completed", "Current", "Future"];

export default function Phases() {
  const { navigate } = useUtilities();
  const { web } = useSearch();
  const { data: phases, isLoading } = usePhases();
  const { data: DefaultPhases, isLoading: loadingDefaultPhases } =
    useDefaultPhases();
  const { mutate: addPhase, isLoading: addingPhase } = useAddDefaultPhase();
  const { mutate: createPhase, isLoading: creatingPhase } = useAddNewPhase(
    "",
    true
  );
  const { mutate: deletePhase, isLoading: deletingPhase } =
    useDeleteDefaultPhase();
  const [addPopup, setAddPopup] = useState(false);
  const [libraryTemplate, setLibraryTemplate] = useState(false);
  const [filteredList, setFilteredList] = useState({});
  const {
    add_new_items_to_site_template,
    add_items_from_template_library,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  const { mutate: updatePhaseOrder } = useUpdatePhasesOrder();

  useEffect(() => {
    let temp = {};
    DefaultPhases.forEach((phase) => {
      let status = parse(phase.status).text;
      if (!web) {
        if (temp["templates"]) {
          temp["templates"].push(phase);
        } else {
          temp["templates"] = [phase];
        }
      } else {
        if (temp[status]) {
          temp[status].push(phase);
        } else {
          temp[status] = [phase];
        }
      }
    });
    setFilteredList(temp);
  }, [DefaultPhases]);
  const defaultPhaseParentIDS = useMemo(
    () => DefaultPhases.map((e) => e.parent_item).filter((e) => e),
    [DefaultPhases]
  );

  const sortPhases = (phaseKeys) => {
    let tempPhaseKeys = phasesList.filter((phase) => phaseKeys.includes(phase));
    return [
      ...tempPhaseKeys,
      ...phaseKeys.filter((phase) => !tempPhaseKeys.includes(phase)),
    ];
  };

  const handleAddPhase = (phase) => {
    if (web) {
      createPhase({ ...phase, website_id: web, parent_item: phase.id });
      return;
    }
    addPhase({ id: phase.id });
  };

  const getPhaseSecondaryData = (phase, key) => {
    if (web) {
      if (phase.target_date) {
        return t(
          key === "Completed"
            ? "siteSetting.phases.endedOn"
            : "siteSetting.phases.endsOn",
          {
            targetDate: new Date(phase.target_date).toLocaleDateString("en-ZA"),
          }
        );
      }
      return "";
    } else {
      if (phase.target_date_days && !isEqual(phase.target_date_days, 0)) {
        return t("siteSetting.phases.remainingDays", {
          remainingDays: phase.target_date_days,
        });
      }
    }
  };

  const handleChecklistTaskDragEnd = (event, status) => {
    const { active: sourceElement, over: targetElement } = event;
    if (sourceElement.id === targetElement.id) return;
    const test = { ...filteredList };
    const newItems = [...test[status]];
    const [reorderedItem] = newItems.splice(sourceElement.id, 1);
    newItems.splice(targetElement.id, 0, reorderedItem);
    updatePhaseOrder(
      newItems.map((ele, index) => ({ id: ele.id, prno: index + 1 }))
    );
    test[status] = newItems;
    setFilteredList(test);
  };

  return (
    <>
      <RightMainContainer>
        <Loader
          loader="block"
          show={addingPhase || deletingPhase || creatingPhase}
        />
        <HeaderContainer>
          <PrimaryHeading>{t("siteSetting.phases.title")}</PrimaryHeading>
          <div className="relative">
            {add_new_items_to_site_template ? (
              <MainButton onClick={() => setAddPopup(!addPopup)}>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            ) : (
              ""
            )}
            <PopOver
              open={addPopup || libraryTemplate}
              onClose={() => {
                setAddPopup(false);
                setLibraryTemplate(false);
              }}
            >
              {addPopup ? (
                <PopUpListing
                  data={[
                    add_items_from_template_library && {
                      title: t("common.dropDown.fromTemplateLibrary"),
                      icon: <CloudDownloadIcon />,
                      onClick: () => {
                        setLibraryTemplate(true);
                        setAddPopup(false);
                      },
                      arrow: true,
                    },
                    {
                      title: t("siteSetting.phases.createNewPhase"),
                      icon: <AddIcon />,
                      onClick: () => {
                        navigate(
                          `/site-settings/phases/add${web ? "?web=" + web : ""}`
                        );
                      },
                    },
                  ].filter((_) => _)}
                />
              ) : (
                <AddFromTemplateLibrary
                  isLoading={isLoading}
                  data={phases
                    .filter((_) => {
                      if (!web) return !isEqual(_.default_tem, "1");
                      return !defaultPhaseParentIDS.includes(String(_.id));
                    })
                    .map((phase) => ({
                      ...phase,
                      icon: <PhaseIcon />,
                      onClick: () => handleAddPhase(phase),
                    }))}
                  emptyTemplate={{
                    label: t("siteSetting.phase"),
                    linkLabel: t("siteSetting.phase"),
                    link: `/site-settings/phases/add${
                      web ? "?web=" + web : ""
                    }`,
                  }}
                />
              )}
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {loadingDefaultPhases ? (
              <LoadingListCards />
            ) : isEmpty(filteredList) ? (
              <TemplateNotFound
                label={t("siteSetting.phase")}
                linkLabel={t("siteSetting.phase")}
                onClick={
                  add_new_items_to_site_template
                    ? () => setAddPopup(true)
                    : null
                }
                filter={false}
                link={
                  add_new_items_to_site_template
                    ? `/site-settings/phases${web ? "?web=" + web : ""}`
                    : ""
                }
              />
            ) : (
              sortPhases(Object.keys(filteredList)).map((key, index) => {
                return (
                  <>
                    {web && key && !isEqual(key, undefined) ? (
                      <SecondaryHeading key={index}>{key}</SecondaryHeading>
                    ) : (
                      ""
                    )}
                    <SortableList
                      onDragEnd={(event) =>
                        handleChecklistTaskDragEnd(event, key)
                      }
                      items={filteredList[key].map((o, index) => {
                        if (o.hasOwnProperty("id")) {
                          return index;
                        }
                      })}
                    >
                      {filteredList[key].map((phase, index) => {
                        return (
                          <ListCard
                            id={index}
                            key={filteredList.length + "-" + phase.id}
                            opacity={
                              web && key === "Completed" ? "opacity-50" : ""
                            }
                            secondaryData={getPhaseSecondaryData(phase, key)}
                            editIcon={edit_item_details_in_site_template}
                            allSorting={!web}
                            editLink={
                              edit_item_details_in_site_template
                                ? `/site-settings/phases/${phase.id}`
                                : ""
                            }
                            link={
                              edit_item_details_in_site_template
                                ? `/site-settings/phases/${phase.id}`
                                : ""
                            }
                            backUrlOnEdit={false}
                            data={phase.title}
                            deleteIcon={
                              !web || !remove_items_from_site_template
                            }
                            crossIcon={!web && remove_items_from_site_template}
                            onCross={() =>
                              deletePhase({
                                id: phase.id,
                                website_id: web,
                              })
                            }
                            sortable={!web}
                            icon={
                              <>
                                <ListIconWrapper>
                                  <PhaseIcon />
                                </ListIconWrapper>
                              </>
                            }
                            deleteTitle={
                              web
                                ? t(
                                    "siteSetting.phases.deleteConfirmation.fromSite.title"
                                  )
                                : t(
                                    "siteSetting.phases.deleteConfirmation.title"
                                  )
                            }
                            showModalonCross
                            deleteButtonText={
                              web
                                ? t(
                                    "siteSetting.phases.deleteConfirmation.fromSite.buttonText"
                                  )
                                : t(
                                    "siteSetting.phases.deleteConfirmation.buttonText"
                                  )
                            }
                            deleteMessage={
                              web
                                ? t(
                                    "siteSetting.phases.deleteConfirmation.fromSite.message"
                                  )
                                : t(
                                    "siteSetting.phases.deleteConfirmation.message"
                                  )
                            }
                          />
                        );
                      })}
                    </SortableList>
                  </>
                );
              })
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
