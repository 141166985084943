import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PreviewCard,
  LoadingCard,
  Loader,
  MainButton,
  InputField,
  IconWrapper,
  BodyContainer,
  PrimaryHeading,
  HeaderContainer,
  ColorPicker,
  RightMainContainer,
  DeleteButton,
} from "commonUI";
import { BackArrow, SaveIcon } from "allIcons";
import {
  getUserSavePermission,
  titleCase,
  useSearch,
  useUtilities,
} from "helpers";
import {
  useAddPriority,
  useDeletePriority,
  usePriority,
  useUpdatePriority,
} from "hooks/priority";
import { useUserPermissions } from "hooks/user";

export default function PrioritySetting() {
  const { notification } = useUtilities();
  const navigate = useNavigate();
  const { web = null } = useSearch();
  const { isLoading, error, data: priorities } = usePriority();
  let { id } = useParams();
  let priority = priorities.filter((element) => element.id == id)[0] ?? {};
  const [value, setValue] = useState(priority?.color ?? "#0066ff");
  const [inputValue, setInputValue] = useState(
    titleCase(priority?.priority_title ?? "")
  );
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  useEffect(() => {
    if (id !== "add" && !isLoading) {
      let priority = priorities.filter((element) => element.id == id)[0] ?? {};
      if (!priority?.id) {
        navigate(`/site-settings/priority${web ? `?web=${web}` : ""}`);
        return;
      }
      setValue(priority?.color);
      setInputValue(priority?.priority_title);
    }
  }, [isLoading, priorities, id]);

  const addMutation = useAddPriority("/site-settings/priority/");
  const updateMutation = useUpdatePriority();
  const deleteMutation = useDeletePriority(
    `/site-settings/priority${web ? `?web=${web}` : ""}`
  );

  function handleSaveChanges() {
    if (!inputValue || !value) {
      notification({
        type: "error",
        message: `Please enter ${inputValue ? "color" : "title"}`,
      });
      return;
    }
    if (id === "add") {
      addMutation.mutate({
        priority_title: inputValue,
        color: value,
        website_id: web,
      });
    } else {
      updateMutation.mutate({
        priority_title: inputValue,
        color: value,
        priority_id: id,
        website_id: web,
      });
    }
  }

  return (
    <>
      <RightMainContainer>
        {(addMutation.isLoading ||
          updateMutation.isLoading ||
          deleteMutation.isLoading) && <Loader loader="block" />}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(`/site-settings/priority${web ? `?web=${web}` : ""}`)
              }
            />
            {isLoading ? (
              <LoadingCard className="w-40 h-6" />
            ) : (
              <PrimaryHeading>{inputValue ?? "Add Priority"}</PrimaryHeading>
            )}
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && remove_items_from_site_template && web}
              deleteMessage={
                web
                  ? "You are about to delete this priority option from your site. This action cannot be undone! Any item using this priority option will revert to the one before it. Are you sure you want to do this?"
                  : "You are about to remove this priority option from your default site template. Are you sure you want to do this?"
              }
              deleteTitle={
                web ? "Delete This Priority?" : "Remove This Priority?"
              }
              deleteButtonText={web ? "Confirm Delete" : "Confirm"}
              onDelete={() => deleteMutation.mutate({ id })}
              isDeleteButton={!!web}
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_items_to_site_template,
              editPermission: edit_item_details_in_site_template,
            }) ? (
              <MainButton onClick={handleSaveChanges}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <div>
            <InputField
              label="Title"
              placeholder="Write Title Here"
              value={inputValue}
              setValue={setInputValue}
              isLoading={isLoading}
            />
          </div>
          <div>
            <PreviewCard
              title={inputValue}
              color={(value?.[0] ?? "") == "#" ? value : "#" + value ?? ""}
              isLoading={isLoading}
              icon={true}
            />
          </div>
          <div>
            <InputField
              label="Color"
              placeholder="Write Color Here"
              value={(value?.[0] ?? "") == "#" ? value : "#" + value ?? ""}
              setValue={setValue}
              isLoading={isLoading}
            />
          </div>
          <div>
            <ColorPicker
              value={(value?.[0] ?? "") == "#" ? value : "#" + value ?? ""}
              setValue={setValue}
            />
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
